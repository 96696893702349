import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';

import Masonry from 'react-masonry-component';

import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Project
import Project from '../components/';

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		return (
			
			<Template modifiers={['news']}>
				<FadeIn key={entry.id}>

					<div className="Template-head">
						<Data>
							{
								({EXPERIENCE,CUISINE,LOCATION}) => {
									if(EXPERIENCE){
										return (<Content html={`<h1>${EXPERIENCE.title.decodeHTML()}</h1>${EXPERIENCE.content}`} />)
									}else{
										return (<Content html={`<h1>${entry.title.decodeHTML()}</h1>${entry.content}`} />)
									}
								}
							}
						</Data>
						
					</div>

					<div className="Template-filter">
						<Data>
							{
								({EXPERIENCE,CUISINE,LOCATION}) => {
									return (
										
										<React.Fragment>
										<div className="Template-dropdown Template-dropdown--long">
						<Toggle autoclose tag={'div'} className="Dropdown">
                          <div className="Dropdown-head">
                            {(EXPERIENCE) ? `Filtered by ${EXPERIENCE.title.decodeHTML()}` : `Filter By Offering`}
                          </div>
                          <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
                          <div className="Dropdown-body">
                            <ul className="List List--dropdown">
                            	<li><Link navlink hashLink hashKey={'experience'} hashVal={'any'} matchUri={`experience/any/`}>All</Link></li>
                                <Entry id={entry.dining_experience} render={(e)=>{
                                	return (<li><Link navlink hashLink hashKey={'experience'} hashVal={e.slug} matchUri={`experience/${e.slug}/`}>{e.title.decodeHTML()}</Link></li>)
                                }} />
                            </ul>
                          </div>
                        </Toggle>
                        </div>

                        <div className="Template-dropdown Template-dropdown--long">
						<Toggle autoclose tag={'div'} className="Dropdown">
                          <div className="Dropdown-head">
                            {(CUISINE) ? `${CUISINE.title.decodeHTML()}` : `Filter By Cuisine`}
                          </div>
                          <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
                          <div className="Dropdown-body">
                            <ul className="List List--dropdown">
                            	<li><Link navlink hashLink hashKey={'cuisine'} hashVal={'any'} matchUri={`cuisine/any/`}>All</Link></li>
                                <Entry id={entry.cuisine} results={(results)=>{
					                                	
                                	let filterList = results.propSort('title');
                                	return filterList.map((c) => (<li><Link navlink hashLink hashKey={'cuisine'} hashVal={c.slug} matchUri={`cuisine/${c.slug}/`}>{c.title.decodeHTML()}</Link></li>))
                                	
                                }} />
                            </ul>
                          </div>
                        </Toggle>
                        </div>

                        
										</React.Fragment>

			                           )
			                    }
			                }
			            </Data>

			            

					</div>



					<div className="Template-body">
					{console.log(entry)}
						<FadeIn duration={0.5} key={entry.id}>
						<Data require={['entries']}>
							{
								(data) => {

								
									// Get entries related to this entry, sort by title
									let venues = data.entries.filter((e) => (entry.related_venues.indexOf(e.id) != -1)).propSort('title',1);
									let categories = data.categories;

									

									if(data.LOCATION){
										venues = venues.filter((v)=>(v.hasOwnProperty('related_areas') && v.related_areas.indexOf(data.LOCATION.id) != -1));
									}

									if(data.CUISINE){
										venues = venues.filter((v)=>(v.hasOwnProperty('related_cuisine') && v.related_cuisine.indexOf(data.CUISINE.id) != -1));
									}

									if(data.EXPERIENCE){
										venues = venues.filter((v)=>(v.hasOwnProperty('related_dining_experience') && v.related_dining_experience.indexOf(data.EXPERIENCE.id) != -1));
									}

									


									if(!venues.length){
										return (
											<div className="Template-no-results">
											
											<Content modifier="intro">
												<p>No Results</p>
											</Content>
											
											</div>
										)
									}

									
									
									return (
									<div data-grid-of="4" data-mobile-grid-of="2">
			                      	{venues.map(
											(v) => {
												return (
													<div className="List-item" key={v.id}>
													<Item
														modifier="venue"
														head={
															<Content>

																<h3>{v.title.decodeHTML()}</h3>

															</Content>
														}
														body={
															<React.Fragment>
																<Content html={v.address} />
															</React.Fragment>
														}
														media={<Link to={v.uri}><div className="Item-image1"><Media id={v.illustration} fluid={false} ratio={6/4} /></div><div className="Item-image2"><Media id={((v.gallery.length) ? v.gallery[0].id : v.thumbnail )} fluid={true} /></div></Link>}
														foot={
															<React.Fragment>
																<Link className="Link Link--button" to={v.uri}>Find Out More</Link>
																<Link className="Link Link--button" to={v.website}>Visit Website</Link>
															</React.Fragment>
														}
													/>
													</div>
												);
											}
										)}
			                      </div>
									
									)

								}
							}
						</Data>
						</FadeIn>
					</div>

				</FadeIn>
			</Template>
		
		);

	}
}

export default withStore(Default);
