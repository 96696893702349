import React, { Component } from 'react';

import Form from '../../_core/components/Form';
import FadeIn from '../../_core/components/FadeIn';
import JumpLink from '../../_core/components/JumpLink';
import Template from '../../_core/components/Template';
import Content from '../../_core/components/Content';
import Link from '../../_core/components/Link';
import Image from '../../_core/components/Image';
import Entry from '../../_core/models/Entry';
import Item from '../_elements/Item.js';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import ViewportProgress from '../../_core/components/ViewportProgress';

// Project
import Project from '../../components/';


class Default extends Component {



	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	
	}

	componentWillUnmount() {

	}




	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {item} = this.props;

		return (

			<div className="Template-intro">
				<div>
					<Content modifier="intro" html={item.intro} />
					<Content modifier="intro_text" html={item.text} />
				</div>
			</div>
			)

	

	}
}

export default Default;
