import React, { Component } from 'react';

import Content from '../../_core/components/Content';
import Image from '../../_core/components/Image';

import Slider from "react-slick";

import Icon from '../../_core/components/Icon';

let placeholder = {

  modifier : 'default',
  items : [
    {
      image :<Image src={`/ui/img/placeholder/1.jpg`} fluid={true} />,
      caption : <Content modifier="caption" html={`Image 1`} />
    },
    {
      image :<Image src={`/ui/img/placeholder/2.jpg`} fluid={true} />,
      caption : <Content modifier="caption" html={`Image 2`} />
    },
    {
      image :<Image src={`/ui/img/placeholder/3.jpg`} fluid={true} />,
      caption : <Content modifier="caption" html={`Image 3`} />
    }
  ]

}

class Com extends Component {


  constructor(props){

      super(props);
      this.state = {
        nav1: null,
        nav2: null
      };
  }

  componentDidMount = () => {

      const _this = this;

      this.setState({
      nav1: this.slider1
    });

  }

  stopAutoPlay = () => {

    if(this.slider1){
      this.slider1.slickPause();
      this.slider2.slickPause();
    }

  }

  render() {   

    let settings = {
   
      dots: true,
      infinite: true,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode : false,
      pauseOnHover : false,
      //lazyLoad: 'anticipated',
      delay:5000,
      fade:false,
      autoplay:true,
      appendDotsOff : dots => {

        let active = dots.indexOf(dots.find(d=>(d.props.className == 'slick-active')));
        return <div><Content modifier={'caption'}><p>{(active + 1)}/{dots.length}</p></Content></div>

      }
    };

    let props = {...placeholder,...this.props};

    if(this.props.settings) settings = {...settings,...this.props.settings};

    return (
      
        <div className={`Carousel Carousel--${props.modifier}`}>
          <div className="Carousel-body" onClick={this.stopAutoPlay}>
          <Slider {...settings}  asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
          {props.items.map((item,ix)=>(
            <div key={'item_'+ix} className="Carousel-slide">
              {item.image}
            </div>
          ))}
          </Slider>


          <div className="CarouselSingle-captions">
            <Slider speed={500} fade={true} dots={false} arrows={false} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}>
                
                    {props.items.map((i,ix)=>(<div key={'slide'+ix}>{i.caption ? i.caption : null}</div>))}
                
              </Slider>
          </div>


          </div>
          
        </div>
               
    );
  }
}



export default Com;
