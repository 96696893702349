const Includes = {

}

const layouts = require.context('./_layouts', false, /^(?!.*_).*\.js$/);

layouts.keys().forEach(filename => {
  
  Includes[filename.replace('./','').replace('.js','')] = layouts(filename).default;

});

const interactions = require.context('./_interactions', false, /^(?!.*_).*\.js$/);

interactions.keys().forEach(filename => {
  
  Includes[filename.replace('./','').replace('.js','')] = interactions(filename).default;

});

export default Includes;

