import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Icon from '../../_core/components/Icon';
import Content from '../../_core/components/Content';
import Html from '../../_core/components/Html';
import Data from '../../_core/models/Data';
import Project from '../../components';

function Submenu({menu,modifier='sub'}){

  if(menu.hasOwnProperty('children') && menu.children.length){

    let isActive = false;

    let active = menu.children.find(c => window.location.pathname.match(c.uri));

    if(active) isActive = true;

    return (<ul className={`List List--${modifier} ${isActive ? 'is-active' : 'is-inactive'}`}>{menu.children.map(
      (v,vix) => (<MenuItem key={`k_${vix}`} menu={v}><Link to={`${v.uri}`} navlink>{v.title.decodeHTML()}</Link></MenuItem>)
    )}</ul>)
  }else{
    return null;
  }


}


function MenuItem({menu,children}){

  // Always close nav on click
  return (
      <SiteModifier demodifier="menu">
        <li>{children}</li>
      </SiteModifier>
    )

   if(menu.hasOwnProperty('children') && menu.children.length){
    return <li>{children}</li>;
   }else{
    return (
      <SiteModifier demodifier="menu">
        <li>{children}</li>
      </SiteModifier>
    )
   }

}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    return (
      
        <div className="Menu" data-layout data-layout="fluid">
          <div className="Menu-head" data-layout-head>
            
          </div>
          
          <div className="Menu-body" data-layout-back data-layout="fluid">

              <div className="Menu-scroll-content">
              
               <Data require={['HOME']}>
              { (data) => {


                  return (

                    <React.Fragment>
                      <div className="Menu-venues">
                        
                          <ul className="List List--nav">
                            {/*<li><SiteModifier demodifier="menu"><div><Link to={`/`}>Brands</Link></div></SiteModifier>
                              <ul>
                              {data.structures.venues.map(
                                (v,vix) => (<MenuItem key={`k_${vix}`} menu={v}><Link to={`${v.uri}`} navlink>{v.title}</Link><Submenu menu={v} /></MenuItem>)
                              )}
                              </ul>
                            </li>*/}
                            {data.structures.secondary.map(
                                (l,lix) => (<MenuItem key={`sub_${lix}`} menu={l}><Link to={`${l.uri}`} navlink>{l.title}</Link><Submenu menu={l} modifier={'nav'} /></MenuItem>)
                              )}
                          </ul>
                       
                      </div>

                      <div className="Menu-foot">
                        
                          <ul className="List List--nav">
                            {data.structures.main.map(
                              (v,vix) => (<MenuItem key={`k_${vix}`} menu={v}><Link to={`${v.uri}`} navlink>{v.title.decodeHTML()}</Link><Submenu menu={v} /></MenuItem>)
                            )}
                          </ul>
                        

                        <ul className="List List--social">
                          {data.structures.social.map((l,lix) => (
                            <MenuItem key={`k_${lix}`} menu={l}><Link to={l.uri}><Icon glyph type={l.slug} /></Link></MenuItem>
                          ))}
                        </ul>
                        <Content modifier="small">
                          <Html html={data.HOME.footerContact} />
                          <p>{data.HOME.globalPhone}</p>
                        </Content>

                        <Content modifier="small">
                          <Html html={data.HOME.footerNewsletter} />
                        </Content>
                        <Project.Newsletter />

                      </div>
                      </React.Fragment>
                  )

              }}
              </Data>

              </div>


          </div>
        </div>
               
    );
  }
}



export default Com;
