import React, { Component } from 'react';
import Link from '../../_core/components/Link';
import Resize from '../../_core/utils/Resize';
import Content from '../../_core/components/Content';
import Icon from '../../_core/components/Icon';

let placeholder = {

}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  // Height relative to original artboard height (679) scaled to window
  height = (num) => {
    return (num / 679) * window.innerHeight;
  }

  y = (num) => {
    return this.height(num);
  }

  // Width relative to original artboard width (1024) scaled to window
  width = (num) => {
    return (num / 1024) * window.innerWidth;
  }

  x = (num) => {
    return this.width(num);
  }

  render() {    

    let props = {...placeholder,...this.props}
    let modifier = props.modifier || 'default';

    return (
        
        <Resize onResize={() => this.setState({w:window.InnerWidth,h:window.innerHeight})}>
 
          <div data-layout="fluid">

            <svg version="1.1" id="Shapes" xmlns="http://www.w3.org/2000/svg" xmlnsXLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox={`0 0 ${window.innerWidth} ${window.innerHeight}`} style={{"enable-background":`new 0 0 ${window.innerWidth} ${window.innerHeight}`}} xmlSpace="preserve">
                <style type="text/css">
                {
                  `.st0{fill:#FF785A;}
                  .st1{fill:#E5D100;}
                  .st2{fill:#375A64;}
                  .st3{fill:none;stroke:#BE9A5E;stroke-width:12.561;}`
                }
                </style>
                <polyline id="bg_line" class="st3" points={`${this.x(729)},${this.y(679)} ${this.x(729)},${this.y(0)}`} />
            </svg>

          </div>

        </Resize>
    );
  }
}



export default Com;
