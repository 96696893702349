import React, { Component } from 'react';
import SiteModifier from '../../_core/components/SiteModifier';

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    return (
      
          <SiteModifier toggle="menu">
            <div className="Burger">
              <div className="Burger-body">
                <div className="Burger-centre" />
                <div className="Burger-tl" />
                <div className="Burger-tr" />
                <div className="Burger-br" />
                <div className="Burger-bl" />
              </div>
            </div>
          </SiteModifier>
               
    );
  }
}



export default Com;
