import React, { Component } from 'react';

import Form from '../../_core/components/Form';
import FadeIn from '../../_core/components/FadeIn';
import JumpLink from '../../_core/components/JumpLink';
import Template from '../../_core/components/Template';
import Content from '../../_core/components/Content';
import Link from '../../_core/components/Link';
import Image from '../../_core/components/Image';
import Entry from '../../_core/models/Entry';
import Item from '../_elements/Item.js';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import ViewportProgress from '../../_core/components/ViewportProgress';

// Project
import Project from '../../components/';


class Default extends Component {

	slides = 0;
	trackRef = false;

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	
	}

	componentWillUnmount() {

	}

	scrollTrack = ({progress}) => {

	
		if(!this.trackRef) return;
		
		let _this = this;

		let pos = progress * (50 * (this.slides + 1));
		let max = (50 * (this.slides - 1));

		let move = Math.min(pos,max);
		window.TweenLite.set(this.trackRef,{x:`-${move}vw`});

	}


	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {items} = this.props;
		_this.slides = items.length;

		if(!items.length) return null;
		
		return (

			<Template modifiers={["default","slides"]}>

				<div className="Template-sticky-wrapper Template-scroller-wrapper">
						<ViewportProgress start={0} onProgress={this.scrollTrack}>
						{items.map(item =>{
							return (

								<div className="Template-section">
								<div className="Template-page">
								<Item
									modifier="page"
									head={
										<Content>
											<h3>{item.pre_header.decodeHTML()}</h3>
											<h1>{item.title.decodeHTML()}</h1>
										</Content>
									}
									media={<Media id={item.illustration} fluid={false} ratio={6/4} />}
									foot={<Content modifier="page" html={`${item.text}`} />}
								/>
								</div>
								</div>

						)})}
						</ViewportProgress>

						<div className="Template-gallery Template-scroller-wrapper">
							<div className="Template-sticky">
			
								<div className="Template-scroller">
									<div className="Template-scroller-track" ref={(ref)=>_this.trackRef = ref}>
									{items.map(item =>{
										return <div className="Template-scroller-item"><Media id={item.image} fluid={true} /></div>
									})}
									</div>
								</div>
							</div>
						</div>
					</div>

			</Template>
			)

	

	}
}

export default Default;
