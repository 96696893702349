import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';
import Html from '../_core/components/Html';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';

import Masonry from 'react-masonry-component';

import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Project
import Project from '../components/';

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		return (
			<FadeIn key={entry.id}>
		
			<Template modifiers={["article"]}>

			
					<Item
						modifier="article"
						head={
							
							<Content>
								<h1>{entry.title.decodeHTML()}</h1>
							</Content>
						}
						body={
							<React.Fragment>
							
							{(()=>{
								if(this.props.career){
									return (
									<div className="Item-image">
										<Image src={entry.image} fluid={false} lazy={true} ratio={6/4} />
									</div>
									)

								}else{
									return (
									<div className="Item-image">
										<Media id={entry.image} fluid={false} lazy={true}  />
									</div>
									)
								}
							})()}
							

							<Content html={entry.content} />

							{
								(()=>{
									if(entry.application_email){
										return <div className="Template-cta"><Content><h4><a href={`mailto:${entry.application_email}`}>Apply Here</a></h4></Content></div>
									}
								})()
							}

							{
								(()=>{
									if(entry.application_url){
										return <div className="Template-cta"><Content><h4><a href={`${entry.application_url}`}>Apply Here</a></h4></Content></div>
									}
								})()
							}
							</React.Fragment>
						}
						media={false}
						foot={false}
					/>

				

			</Template>
			
			</FadeIn>
		
		);

	}
}

export default withStore(Default);
