import React, { Component } from 'react';
import Data from '../../_core/models/Data';
import Image from '../../_core/components/Image';
import Link from '../../_core/components/Link';
import Icon from '../../_core/components/Icon';
import Content from '../../_core/components/Content';
import Form from '../../_core/components/Form';
import FadeIn from '../../_core/components/FadeIn';

let placeholder = {

	submitText : 'Enter',
	close : <Icon glyph type={'plus'} />,
	content : (
		<div><Content modifier="downloads"><p>Enter your email:</p></Content></div>
	),
	terms : (
		<Content modifier="caption"><p>I agree to terms and conditions</p></Content>
	),
	showGated : (API,response,props) => {

		API.setContent(<FadeIn><div className="Downloads-success">{props.gated}</div></FadeIn>);

	},
	onSuccess : () => {

	},
	onError : (API,response,props) => {

		if(response.msg && (response.msg.match('is already subscribed') || response.msg.match('too many recent signup requests *OFF*'))){
			props.showGated(API,response,props);
		}else{
			API.setErrors([response.msg]);
		}

	},
	gated : <div>Thanks!</div>,
	// EIB test list
	domain : `e-i-b.us15.list-manage.com`,
	userId : `83fe1032bae220ec5c92ebfda`,
	listId : `153701ffcb`
}

class LightBox extends Component {



  constructor(props){

      super(props);
      this.state = {modifier:'default'};
  }

  componentDidMount = () => {

      const _this = this;

  }



  render() {    

   	let props = {...placeholder,...this.props};
   	let _this = this;
    return (
        	
			<Data>
				{
					({SHOWDOWNLOADS}) => {

					

							return (
								<div className={`Downloads Downloads--${_this.state.modifier} Downloads--${(SHOWDOWNLOADS) ? 'active' : 'inactive'}`}>
								<div className="Downloads-content">
									
									<div className="Downloads-form" data-layout-center>
									{props.content}
				                    <Form
				                   	
				                   	/*

				                   	<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://e-i-b.us15.list-manage.com/subscribe/post?u=83fe1032bae220ec5c92ebfda&amp;id=153701ffcb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	<h2>Subscribe</h2>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
	<label for="mce-FNAME">First Name </label>
	<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
	<label for="mce-LNAME">Last Name </label>
	<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_83fe1032bae220ec5c92ebfda_153701ffcb" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>

<!--End mc_embed_signup-->

										<form action="https://derwentlondon.us6.list-manage.com/subscribe/post?u=5e104ea2e28a4b8fc7ec478fd&amp;id=e015452a4b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

    <div id="mc_embed_signup_scroll">

               <h2>Subscribe</h2>

<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>

<div class="mc-field-group">

               <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>

</label>

               <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">

</div>

<div class="mc-field-group">

               <label for="mce-FNAME">First Name </label>

               <input type="text" value="" name="FNAME" class="" id="mce-FNAME">

</div>

<div class="mc-field-group">

               <label for="mce-LNAME">Last Name </label>

               <input type="text" value="" name="LNAME" class="" id="mce-LNAME">

</div>

               <div id="mce-responses" class="clear">

                              <div class="response" id="mce-error-response" style="display:none"></div>

                              <div class="response" id="mce-success-response" style="display:none"></div>

               </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->

    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_5e104ea2e28a4b8fc7ec478fd_e015452a4b" tabindex="-1" value=""></div>

    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>

    </div>

</form>

				                   	*/

				                    onSubmit={(data,e,$,API) => {

				                        e.preventDefault();
				                        _this.setState({'modifier':'loading'});
				                   		
				                   		// Must agree trems
				                   		if(!data.agreeTerms){

				                   			API.setErrors(['Please agree to terms']);

				                   			return;
				                   		}

				                   		// Post to Mailchimp
				                        $.post({
				                          url: `//${props.domain}/subscribe/post-json?u=${props.userId}&id=${props.listId}&c=?`,
				                          dataType: 'json',
				                          data: data,
				                          success: function(response) {
				                          	

				                              if (response.result == "success") {
				                                 
				                                 // Success!
				                                 props.showGated(API,response,props);

				                              } else {
				                                 
				                                 // Didn't add to list
				                                 props.onError(API,response,props);
				                                
				                              }

				                              
				                          },
				                          error: function(XMLHttpRequest, textStatus, errorThrown){
				                          	
				                          	console.log('FORM ERROR',XMLHttpRequest);
				                          	API.setErrors(['There was a problem submitting the form, please try again later']);

				                          }
				                      });
				                       

				                    }}
				                    ajax={false}
				                    method="post"
				                    action={'/'}
				                    submit={props.submitText}
				                    fields={[
				                  
				                  {
				                    name: `b_${props.userId}_${props.listId}`,
				                    type: 'text',
				                   	modifier : 'hon',
				                   	style:{position: `absolute`, left: `-5000px`}
				                  },
				                  {
				                    name: 'EMAIL',
				                    type: 'email',
				                    placeholder: 'Your Email'
				                  },
				                  {
				                  	type : 'checkbox',
				                  	name: 'agreeTerms',
				                  	value : false,
				                  	caption : props.terms
				                  }

				                  ]}>
				                    </Form>
				                    </div>

									<Link className="Downloads-close" to={`#/back`}>{props.close}</Link>
								</div>
								</div>
							)
					

					}
				}
			</Data>
				
    
    );
  }
}



export default LightBox;
