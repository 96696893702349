import React, { Component } from 'react';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Loader from '../_core/components/Loader';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';

import {withStore} from '../_core/utils/Store';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';

import Masonry from 'react-masonry-component';

import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Project
import Project from '../components/';
import {WP_careersTransformer} from '../transformers/wordpress.js';

const d = new Date();
let STAGE = false;

let STAGING_FEED = `//proxy.olson.work?p=http://pb4test.peoplebank.com/pb3/corporate/jksrestaurants/feed.php?nc=${d.getTime()}`;
let LIVE_FEED = `https://www.peoplebank.com/pb3/corporate/jksrestaurants/feed.php`;

let FEED_URL = (STAGE) ? STAGING_FEED : LIVE_FEED;

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		
		this.props.store.store('CTA',{
			'link' : `${this.props.entry.vacancies_email}`,
			'text' : this.props.entry.vacancies_email_text
		});

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		return (
			<FadeIn key={entry.id}>
			<React.Fragment>
			<Template modifiers={["default"]}>

				<div className="Template-section">
					<div className="Template-page">
					<Item
						modifier="template"
						head={
							<Content>
								<h1>{entry.title.decodeHTML()}</h1>
							</Content>
						}
						body={
							<React.Fragment>
								<Content modifier="opener" html={entry.intro} />
								<Content html={`<p>${entry.intro_call_to_action}</p>`} />
							</React.Fragment>
						}
						media={false}
						foot={<Content modifier="page" html={entry.content} />}
					/>
					</div>
				</div>
				

				<div className="Template-gallery">
					<Media id={entry.image} fluid={true} />
				</div>

			</Template>
			<Loader wait={0} manifest={[
		{
		name:'careers',
		src:FEED_URL,
		type:'json',
		transformer : WP_careersTransformer
		}]} progress={()=><div className="Template-loader"><Content><p>Loading...</p></Content></div>} loaded={

                  ()=>(
            
                    <div />
               
                  )

              }/>
			<Template modifiers={['news']}>
				

					<div className="Template-head">
						<Content html={`<h1>${entry.vacancies_title.decodeHTML()}</h1>${entry.vacancies_text}`} />
					</div>

					<div className="Template-filter">
						<Data require={['careers']}>
							{
								(data) => {

									let VENUE_SLUG = data.VENUE_SLUG;
									let ROLE_SLUG = data.ROLE_SLUG;

									let career_venues = {};
									data.careers.forEach((c)=>{
										career_venues[c.related_venue.slug] = c.related_venue;
									})

									let career_roles = {};
									data.careers.forEach((c)=>{
										career_roles[c.related_role.post_name] = {title:c.related_role.post_title,slug:c.related_role.post_name};
									})

									let venues = Object.values(career_venues).propSort('title',1);
									let roles = Object.values(career_roles).propSort('title',1);

									let VENUE = venues.find((v)=>v.slug == VENUE_SLUG);
									let ROLE = roles.find((r)=>r.slug == ROLE_SLUG);

									return (
										<React.Fragment>
										<div className="Template-dropdown">
										<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(VENUE) ? `${VENUE.title.decodeHTML()}` : `Filter Venue`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink hashLink hashKey={'venue'} hashVal={'any'} matchUri={`venue/any/`}>All</Link></li>
				                                {venues.map((v)=>(
				                                	<li key={v.slug}><Link navlink hashLink hashKey={'venue'} hashVal={v.slug} matchUri={`venue/${v.slug}/`}>{v.title.decodeHTML()}</Link></li>
				                                ))}
			                                </ul>
			                              </div>
			                            </Toggle>
			                            </div>
										<div className="Template-dropdown">
										<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(ROLE) ? `${ROLE.title.decodeHTML()}` : `Filter Role`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink hashLink hashKey={'role'} hashVal={'any'} matchUri={`role/any/`}>All</Link></li>
				                                {roles.map((r)=>(
				                                	<li key={r.slug}><Link navlink hashLink hashKey={'role'} hashVal={r.slug} matchUri={`role/${r.slug}/`}>{r.title.decodeHTML()}</Link></li>
				                                ))}
			                                </ul>
			                              </div>
			                            </Toggle>
			                            </div>
			                            </React.Fragment>
			                           )
			                    }
			                }
			            </Data>
					</div>

					<div className="Template-body">
						<Data require={['careers']}>
							{
								(data) => {

									
									let careers = data.careers.propSort('modified',-1);
				
									if(data.ROLE_SLUG && data.ROLE_SLUG != 'any'){
										careers = careers.filter((v)=>(v.hasOwnProperty('related_role') && (v.related_role.post_name == data.ROLE_SLUG)));
									}

									if(data.VENUE_SLUG && data.VENUE_SLUG != 'any'){
										careers = careers.filter((v)=>(v.hasOwnProperty('related_venue') && (v.related_venue.slug == data.VENUE_SLUG)) );
									}

									

									if(!careers.length){
										return (
											<div className="Template-no-results">
											<FadeIn>
											<Content modifier="intro">
												<p>No Vacancies</p>
											</Content>
											</FadeIn>
											</div>
										)
									}

									return (
									<div data-grid-of="4" data-mobile-grid-of="1" data-tablet-grid-of="2">
			                      	{careers.map(
											(c) => {
												return (
													<div className="List-item" key={c.id}>
													<Item
														modifier="career"
														head={
															<Content>

																<h3>{c.title.decodeHTML()}</h3>

															</Content>
														}
														body={
															false
														}
														media={<Link hashLink hashKey={'vacancy'} hashVal={c.id}><Image src={c.image} fluid={false} lazy={true} ratio={6/4} /></Link>}
														foot={
															<Link hashLink hashKey={'vacancy'} hashVal={c.id} className="Link Link--button">Find Out More</Link>
														}
													/>
													</div>
												);
											}
									)}
			                      	</div>
									
									)

								}
							}
						</Data>
					</div>


				
			</Template>
			</React.Fragment>
			</FadeIn>
		
		);

	}
}

export default withStore(Default);
