import React, { Component } from 'react';

import Template from '../_core/components/Template';

import CookieManager from '../_core/components/Cookie/CookieManager';

// Models
import {Asset,Entry,Data} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		

		return (
			
			<Template modifiers={["basic"]}>

				<CookieManager  optionalCookies={
              	[
	                {
	                  name : '_ga',
	                  description : 'Google Analytics cookie to track users as they navigate the website and help improve the website usability',
	                  expiry : '2 Years'
	                },
	                {
	                  name : '_gid',
	                  description : 'Google Analytics cookie to track users as they navigate the website and help improve the website usability',
	                  expiry : '2 Days'
	                },
	                {
	                  name : '_gat',
	                  description : 'Google Analytics cookie to track users as they navigate the website and help improve the website usability',
	                  expiry : '1 Day'
	                }
              	]
            } />

			</Template>
		
		);

	}
}

export default Default;
