import React, { Component } from 'react';
import Data from '../../_core/models/Data';
import Image from '../../_core/components/Image';
import Link from '../../_core/components/Link';
import Icon from '../../_core/components/Icon';
import Content from '../../_core/components/Content';

let placeholder = {

	close : <Icon glyph type={'plus'} />,

}

class LightBox extends Component {



  constructor(props){

      super(props);
      this.state = {progress:0};
  }

  componentDidMount = () => {

      const _this = this;

  }



  render() {    

   	let props = {...placeholder};

    return (
        	
			<Data>
				{
					({SHOWMISREP}) => {

					

							return (
								<div className={`Misrep Misrep--${(SHOWMISREP) ? 'active' : 'inactive'}`}>
								<div className="Misrep-content">
									
									<div data-layout-center>
										<Content modifier="legal">
											<p>Whilst every effort has been made to ensure accuracy, no responsibility is taken for
any error, omission or mis-statement in these particulars which do not constitute an
offer or contract. No representation or warranty whatever is made or given either
during negotiations or in particular by the vendor, lessors or agents Distrkt and
Shackleton. All figures are exclusive of rates, service charge, VAT and all other
outgoings. The agents have not tested the services.</p>
<p>All floor areas are approximate. Date of preparation October 2019.</p>
										</Content>
									</div>
									
									<Link className="Misrep-close" to={`#/back`}>{props.close}</Link>
								</div>
								</div>
							)
					

					}
				}
			</Data>
				
    
    );
  }
}



export default LightBox;
