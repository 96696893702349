import React, { Component } from 'react';
import Link from '../../_core/components/Link';
import Resize from '../../_core/utils/Resize';
import Content from '../../_core/components/Content';
import Icon from '../../_core/components/Icon';
import LogoTicker from './LogoTicker.js';

let placeholder = {

  modifier : 'default',
  text1 : 'Strength with elegance.',
  text2 : 'Lightness of touch.',
  text3 : 'Attention to detail.',
  finalText : 'A warehouse for creative working'
}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    let props = {...placeholder,...this.props}
    let modifier = props.modifier || 'default';

    return (
        

        <div className="Intro" data-layout="fluid" ref={(el) => this.props.setRef && this.props.setRef('wrapper',el)}>
          
         

          <div className="Intro-logo" data-layout-bm ref={(el) => this.props.setRef && this.props.setRef('logo',el)}>
              
             <div className="Logo Logo--black" />

          </div>
         
          <div className="Intro-ticker" data-layout-center ref={(el) => this.props.setRef && this.props.setRef('ticker',el)}>

              <LogoTicker colour="colour" />    

          </div>
        </div>
   
    );
  }
}



export default Com;
