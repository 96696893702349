import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';

import Masonry from 'react-masonry-component';

import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Project
import Project from '../components/';

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		return (
			
			<Template modifiers={['news']}>
				<FadeIn key={entry.id}>

					<div className="Template-head">
						<Content html={`<h1>${entry.title.decodeHTML()}</h1>${entry.content}`} />
					</div>

					<div className="Template-filter">
						<Data>
							{
								({CATEGORY,categories}) => {
									return (
									<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(CATEGORY) ? `${CATEGORY.name}` : `Filter`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink exact to={entry.uri}>All</Link></li>
			                                {categories.map((c)=>(
			                                	<li><Link navlink to={`#/category/${c.slug}/`}>{c.name}</Link></li>
			                                ))}
			                                </ul>
			                              </div>
			                            </Toggle>
			                           )
			                    }
			                }
			            </Data>
					</div>

					<div className="Template-body">
						<Data require={['entries']}>
							{
								(data) => {

						
									let news = data.entries.filter((e) => (e.type == "post")).propSort('postDate',-1);
									let categories = data.categories;

									if(data.CATEGORY){
										news = news.filter((n)=>(n.categories.indexOf(data.CATEGORY.term_id) != -1));
									}

									function Category({id}){
										let found = categories.find((c)=>(c.term_id == id));
										if(found){
											return found.name;
										}else{
											return null;
										}
									}

									if(!news.length){
										return (
											<div className="Template-no-results">
											<FadeIn key={data.CATEGORY.term_id}>
											<Content modifier="intro">
												<p>No {data.CATEGORY.name} Results</p>
											</Content>
											</FadeIn>
											</div>
										)
									}

									return (
									
									<Masonry key={entry.id} className="List List--masonry" options={{columnWidth:".List-sizer",itemSelector:'.List-item',transitionDuration: 0}}>
			                      <div className="List-sizer" />
			                      {news.map(
											(n) => {
												return (
													<div className="List-item" key={n.id}>
													<Item
														modifier="news"
														head={
															<Content>
																<h3>{n.title.decodeHTML()}</h3>
																<h4><DateFormat format={`D MMM`} date={n.postDate} /> / <Category id={n.categories[0]} /></h4>

															</Content>
														}
														body={
															false
														}
														media={<Link hashLink hashKey={'read'} hashVal={n.uri}><Media id={n.image} fluid={false} /></Link>}
														foot={<Link hashLink hashKey={'read'} hashVal={n.uri} className="Link Link--button">Find Out More</Link>}
													/>
													</div>
												);
											}
										)}
			                      </Masonry>
									
									)

								}
							}
						</Data>
					</div>

				</FadeIn>
			</Template>
		
		);

	}
}

export default withStore(Default);
