import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';
import { withRouter } from "react-router-dom";

import hashLink from '../_core/helpers/hashLink';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';
import SiteModifier from '../_core/components/SiteModifier';


import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';



// Project
import Project from '../components/';


import Slider from 'rc-slider';

// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

// Docs: https://github.com/react-component/slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider);


class Default extends Component {

	results=0;
	dragging=false;

	constructor(props) {
		super(props);
		this.state = {party:0,something:false};
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

	RENDER

	*/

	render() {

		let {entry} = this.props;
		const panel_images = entry.panel_images || [];

		return (
		
			<Template modifiers={['private-events']}>

				<FadeIn key={entry.id}>

					<div className="Template-sticky-wrapper">
						<div className="Template-sticky">
						<div className="Template-head">
							<Content html={`${entry.content}`} />
							<Data require={['HOME']}>
							{
								({HOME}) => {
									return <Link className="Link Link--button" allowEmpty={true} to={HOME.global_events_download}>Download {HOME.global_events_download_text}</Link>
								}
							}
							</Data>
							<SiteModifier modifier="panel"><div className="Link Link--button" style={(!entry.show_panel_button) ? {display:'none'} : null}>{entry.panel_button_text}</div></SiteModifier>
							
							<SiteModifier modifier="filters"><div className="Link Link--button Link--filters">Search Venues</div></SiteModifier>
						</div>
						</div>
					</div>

					<div className="Template-sticky-wrapper">
						<div className="Template-sticky">
						<div className="Template-filter">

						<Data>
							{
								({PARTY,OUTSIDE,PRIVATE,LOCATION,categories}) => {

									// Let local state override
									if(this.dragging){
										PARTY = this.state.party;
									}

									if(PARTY){
										PARTY = parseInt(PARTY);
									}

									return (
									<React.Fragment>
										<div className="Template-filter-title">
										<Content>
											<h1>Search Venues</h1>
										</Content>
										</div>
										<div>
										<div className="Template-dropdown">
											<Item

											modifier="slider"
											head={
												<Content>
													<h3> {(PARTY) ? `Party Size: ${PARTY}` : `Party Size: Any`}</h3>
												</Content>
											}

											body={
												<Range defaultValue={((PARTY) ? PARTY : 0)} value={((PARTY) ? PARTY : 0)} tipFormatter={value => `${(value)} people`} min={0} max={parseInt(entry.max_party_size)}

												onChange={(value)=>{

													
													this.dragging = true;
													this.setState({party:value});

												}}

												 onAfterChange={(value)=>{

												 	this.setState({party:value},()=>{

												 		let url = hashLink({
															key:'party',
															value : value,
															removeEmpty: true
														});

														this.dragging = false;
														this.props.history.push(url);

												 	});

													
												}} />
                  							}

                  							/>
										</div>
										
			                            <div className="Template-dropdown Template-dropdown--long">
										<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(LOCATION) ? `${LOCATION.title.decodeHTML()}` : `Location`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink exact to={entry.uri}>All</Link></li>
				                                <Entry id={entry.locations} render={(l)=>{
				                                	return (<li><Link navlink hashLink hashKey={'location'} hashVal={l.slug} matchUri={`location/${l.slug}/`}>{l.title.decodeHTML()}</Link></li>)
				                                }} />
			                                </ul>
			                              </div>
			                            </Toggle>
			                            </div>
			                            <div className="Template-dropdown Template-dropdown--long">
										<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(PRIVATE) ? `Private Dining: ${PRIVATE}` : `Private Dining`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink hashLink hashKey={'private'} hashVal={'any'} matchUri={`private/any/`}>All</Link></li>
				                                <li><Link navlink hashLink hashKey={'private'} hashVal={'yes'} matchUri={`private/yes/`}>Yes</Link></li>
				                                <li><Link navlink hashLink hashKey={'private'} hashVal={'no'} matchUri={`private/no/`}>No</Link></li>
			                                </ul>
			                              </div>
			                            </Toggle>
			                            </div>
			                            <div className="Template-dropdown Template-dropdown--long">
										<Toggle autoclose tag={'div'} className="Dropdown">
			                              <div className="Dropdown-head">
			                                {(OUTSIDE) ? `Outside Area: ${OUTSIDE}` : `Outside Area`}
			                              </div>
			                              <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
			                              <div className="Dropdown-body">
			                                <ul className="List List--dropdown">
			                                	<li><Link navlink hashLink hashKey={'outside'} hashVal={'any'} matchUri={`outside/any/`}>All</Link></li>
				                                <li><Link navlink hashLink hashKey={'outside'} hashVal={'yes'} matchUri={`outside/yes/`}>Yes</Link></li>
				                                <li><Link navlink hashLink hashKey={'outside'} hashVal={'no'} matchUri={`outside/no/`}>No</Link></li>
			                                </ul>
			                              </div>
			                            </Toggle>
			                            </div>
			                            </div>
			                            <div className="Template-filter-close">
										<SiteModifier demodifier="filters"><div><div className="Link Link--button">Show {this.results} Results</div><Link className="Link Link--button" to={window.location.pathname}>Reset</Link></div></SiteModifier>
										</div>
			                            </React.Fragment>
			                           )
			                    }
			                }
			            </Data>
			          	</div>
			          	</div>
					</div>

					<div className="Template-body">
						<FadeIn duration={0.5} key={entry.id}>
						<Data require={['entries']}>
							{
								(data) => {

								
									// Get entries related to this entry, sort by title
									let venues = data.entries.filter((e) => (entry.related_venues.indexOf(e.id) != -1)).propSort('title',1);
									let categories = data.categories;

									if(data.LOCATION){
										venues = venues.filter((v)=>(v.hasOwnProperty('related_areas') && v.related_areas.indexOf(data.LOCATION.id) != -1));
									}

									if(data.PRIVATE){
										let matchPrivate = (data.PRIVATE == 'yes') ? true : false;
										venues = venues.filter((v)=>(v.hasOwnProperty('private_dining') && v.private_dining == matchPrivate));
									}

									if(data.OUTSIDE){
										let matchOutside = (data.OUTSIDE == 'yes') ? true : false;
										venues = venues.filter((v)=>(v.hasOwnProperty('outside_area') && v.outside_area == matchOutside));
									}

									let PARTY = data.PARTY;
									// Let local state override
									if(this.dragging){
										PARTY = this.state.party;
									}

									if(PARTY){
										PARTY = parseInt(PARTY);
									}

									if(PARTY){
										venues = venues.filter((v)=>((v.hasOwnProperty('min_party_size') && parseInt(v.min_party_size) <= PARTY) && (v.hasOwnProperty('max_party_size') && parseInt(v.max_party_size) >= PARTY)));
									}


									if(!venues.length){
										return (
											<div className="Template-no-results">
											
											<Content modifier="intro">
												<p>No Results</p>
											</Content>
											
											</div>
										)
									}

									// Update result count
									if(this.results != venues.length){
										this.results = venues.length;
										this.setState({results:this.results});
									}
									
									return (
									<div data-grid-of="2" data-mobile-grid-of="1">
			                      	{venues.map(
											(v) => {
												return (
													<div className="List-item" key={v.id}>
													<Item
														modifier="venue"
														head={
															<Content>

																<h3>{v.title.decodeHTML()}</h3>

															</Content>
														}
														body={
															null
														}
														media={<Link to={v.uri}><div className="Item-image1"><Media id={v.illustration} fluid={false} ratio={6/4} /></div><div className="Item-image2"><Media id={((v.private_dining_image) ? v.private_dining_image : v.gallery[0].id )} fluid={true} /></div></Link>}
														foot={
															<React.Fragment>
																<Link className="Link Link--button" to={(v.event_enquiry_url) ? v.event_enquiry_url : v.uri}>{entry.enquiry_link_text}</Link>
																<Link className="Link Link--button" to={v.website}>{entry.visit_website_link_text}</Link>
															</React.Fragment>
														}
													/>
													</div>
												);
											}
										)}
			                      </div>
									
									)

								}
							}
						</Data>
						</FadeIn>
					</div>

				</FadeIn>

				<div className="Template-panel">
					<Template modifiers={["default"]}>

						<div className="Template-section">
							<div className="Template-page">
							<Item
							modifier="template"
							head={
								<Content>
									<h1>{entry.panel_title.decodeHTML()}</h1>
								</Content>
							}
							body={
								<React.Fragment>
									<Content modifier="opener" html={entry.panel_intro} />
								</React.Fragment>
							}
							media={false}
							foot={<Content modifier="page" html={entry.panel_text} />}
						/>
							</div>
						</div>
						

						<div className="Template-gallery">
							<Project.Carousel modifier="gallery"
								items={panel_images.map((image)=>{
									return {
										image : <Media id={image} fluid={true} />
									}
								})}
							/>
						</div>

						<SiteModifier demodifier="panel"><div className="Template-close"><Icon glyph type={'cross'} /></div></SiteModifier>

					</Template>
				</div>
			</Template>
		
		);

	}
}

export default withStore(withRouter(Default));
