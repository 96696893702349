import React, { Component } from 'react';
import Link from '../../_core/components/Link';
import Resize from '../../_core/utils/Resize';
import Content from '../../_core/components/Content';
import Icon from '../../_core/components/Icon';
import Data from '../../_core/models/Data';
import Entry from '../../_core/models/Entry';
import Asset from '../../_core/models/Asset';
import Media from '../../_core/components/Media';
import Image from '../../_core/components/Image';

let placeholder ={
  colour : 'colour'
}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    let props = {...placeholder,...this.props}
    let modifier = props.modifier || 'default';

    return (
        

              <div className="Ticker">
              <ul>
              <Data require={['structures','entries']}>
              { (data) => {


                  let uris = data.structures.venues.map((v) => v.uri);

                  let logos = uris.map((uri)=>{

                      let found = data.entries.find(e => (e.hasOwnProperty('uri') && (e.uri == uri)));
                      if(typeof found == 'undefined') return false;
                      return found;

                  });

                  return logos.concat(logos).map((e,ix) => {

                    let uri = e.hasOwnProperty('uri') ? e.uri : null;
                    return (
                    <li key={`logo_${ix}`}>
                    <Link to={uri}>
                    <Asset id={(props.colour == 'white') ? [e.white_ticker_logo] : [e.colour_ticker_logo]} render={(a)=>{
                      return (<div className="Ticker-logo"><Image src={a.url} fluid={false} lazy={false} ratio={8/4} /></div>)
                    }} />
                    </Link>
                    </li>)

                  });

              }}
              </Data>
              </ul>
              </div>
            
   
    );
  }
}



export default Com;
