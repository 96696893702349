import React, { Component } from "react";

import { withStore } from "../_core/utils/Store";
import ScrollTo from "../_core/components/ScrollTo";
import tinycolor from "tinycolor2";

import FadeIn from "../_core/components/FadeIn";
import JumpLink from "../_core/components/JumpLink";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import Link from "../_core/components/Link";
import Image from "../_core/components/Image";
import Media from "../_core/components/Media";

import Entry from "../_core/models/Entry";
import Data from "../_core/models/Data";

// Project
import Project from "../components/";
import Item from "../components/_elements/Item.js";
import TintedMap from "../components/_elements/Map.js";

import mapTint from "../_core/helpers/mapTint";

class Home extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store("CTA", false);
	}

	componentWillUnmount() {}

	/*

		RENDER

	*/

	render() {
		let { entry } = this.props;
		if (
			entry.hasOwnProperty("related_venues") &&
			entry.related_venues.length
		) {
			// Multi-Venue Overview
			return (
				<Template modifiers={["venues"]}>
					<FadeIn key={entry.id}>
						<div
							className="Template-overview-list"
							data-grid-of="3"
							data-tablet-down-grid-of="1"
						>
							<Entry
								id={entry.related_venues}
								render={(entry) => {
									return (
										<div>
											<Item
												modifier="overview"
												head={
													<Content>
														<h3>
															{entry.title.decodeHTML()}
														</h3>
													</Content>
												}
												body={
													<Content
														html={entry.address}
													/>
												}
												media={
													<Link to={entry.uri}>
														<Media
															id={
																entry.illustration
															}
															fluid={false}
															ratio={6 / 4}
														/>
													</Link>
												}
												foot={
													<Link
														className="Link Link--button"
														to={entry.uri}
													>
														Find Out More
													</Link>
												}
											/>
										</div>
									);
								}}
							/>
						</div>
					</FadeIn>
				</Template>
			);
		} else {
			//console.log(entry);

			let showMap = window.location.hash.match("map");
			let map = showMap ? "map" : "nomap";

			let color = tinycolor(entry.map_tint);
			let textColor = tinycolor(entry.pin_background);

			let buildings = color.clone().lighten(0).toHexString();
			let road = color.clone().darken(5).toHexString();
			let water = color.clone().darken(20).toHexString();
			let parks = color.clone().lighten(10).toHexString();

			let text = tinycolor.mix(textColor, color, 10).toHexString();
			let strokeLightness =
				tinycolor.readability(text, road) < 1.5 ? 20 : 0; // Up to 21. See: https://github.com/bgrins/TinyColor
			let textStroke = color
				.clone()
				.lighten(strokeLightness)
				.toHexString();

			let mapStyle = mapTint(buildings, road, water, parks);

			let mapOverrides = [
				{
					featureType: "all",
					elementType: "labels.text.fill",
					stylers: [
						{
							saturation: null,
						},
						{
							color: text,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "all",
					elementType: "labels.text.stroke",
					stylers: [
						{
							saturation: null,
						},
						{
							visibility: "on",
						},
						{
							color: textStroke,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "administrative",
					elementType: "geometry.fill",
					stylers: [
						{
							color: buildings,
						},
						{
							lightness: 10,
						},
					],
				},
				{
					featureType: "administrative",
					elementType: "geometry.stroke",
					stylers: [
						{
							color: buildings,
						},
						{
							lightness: null,
						},
						{
							weight: 1.2,
						},
					],
				},
				{
					featureType: "landscape",
					elementType: "geometry",
					stylers: [
						{
							color: buildings,
						},
						{
							lightness: 5,
						},
					],
				},
				{
					featureType: "poi",
					elementType: "geometry",
					stylers: [
						{
							color: buildings,
						},
						{
							lightness: -5,
						},
					],
				},
				{
					featureType: "road.highway",
					elementType: "geometry.fill",
					stylers: [
						{
							color: road,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "road.highway",
					elementType: "geometry.stroke",
					stylers: [
						{
							color: road,
						},
						{
							lightness: null,
						},
						{
							weight: 0.2,
						},
					],
				},
				{
					featureType: "road.arterial",
					elementType: "geometry",
					stylers: [
						{
							color: road,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "road.local",
					elementType: "geometry",
					stylers: [
						{
							color: road,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "transit",
					elementType: "geometry",
					stylers: [
						{
							color: road,
						},
						{
							lightness: null,
						},
					],
				},
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [
						{
							visibility: "off",
						},
						{
							saturation: "-100",
						},
					],
				},
			];
			mapStyle = [...mapStyle, ...mapOverrides];

			// Venue Page
			return (
				<Template modifiers={["venues", map]}>
					<FadeIn key={entry.id}>
						{(() => {
							if (window.innerWidth < 1024) {
								return <ScrollTo auto={true} duration={0.5} />;
							}
						})()}

						<div className="Template-section">
							<div className="Template-page">
								<Item
									modifier="page"
									head={
										<React.Fragment>
											<div className="Item-logo">
												<Media
													id={entry.page_logo}
													fluid={false}
													ratio={9 / 4}
												/>
											</div>
											<div className="Item-sub">
												<Content
													modifier="venueLocation"
													html={`<p>${entry.location}</p>`}
												/>
											</div>
										</React.Fragment>
									}
									innerMedia={
										<Media
											id={entry.illustration}
											fluid={false}
											ratio={6 / 4}
										/>
									}
									foot={
										<React.Fragment>
											<Content
												modifier="address"
												html={entry.address}
											/>
											<div className="Content Content--small">
												<ul className="List List--legal">
													<li>
														<Link
															className={
																showMap
																	? "is-inactive"
																	: "is-active"
															}
															to={"#gallery"}
														>
															Gallery
														</Link>
													</li>
													<li>
														<Link
															navlink
															matchUri={"#map"}
															to={
																showMap
																	? "#gallery"
																	: "#map"
															}
														>
															Map
														</Link>
													</li>
													<li>
														<Link
															to={entry.website}
														>
															Website
														</Link>
													</li>
													<Data require={["HOME"]}>
														{({ HOME }) => {
															return (
																<li>
																	<Link
																		to={
																			HOME.global_events_download
																		}
																	>
																		{HOME.global_events_download_text ||
																			"Events Guide"}
																	</Link>
																</li>
															);
														}}
													</Data>
												</ul>
											</div>
											<Content
												modifier="page"
												html={entry.content}
											/>
										</React.Fragment>
									}
								/>
							</div>
						</div>

						{(() => {
							if (
								entry.call_to_action_text &&
								entry.call_to_action_url
							) {
								return (
									<div className="Template-section Template-section--mobile">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url}
											>
												{entry.call_to_action_text}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_2 &&
								entry.call_to_action_url_2
							) {
								return (
									<div className="Template-section Template-section--mobile">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_2}
											>
												{entry.call_to_action_text_2}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_3 &&
								entry.call_to_action_url_3
							) {
								return (
									<div className="Template-section Template-section--mobile">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_3}
											>
												{entry.call_to_action_text_3}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_4 &&
								entry.call_to_action_url_4
							) {
								return (
									<div className="Template-section Template-section--mobile">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_4}
											>
												{entry.call_to_action_text_4}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						<div className="Template-gallery" id="gallery">
							{(() => {
								if (entry.gallery.length) {
									return (
										<Project.Carousel
											modifier="gallery"
											items={entry.gallery.map(
												(image) => {
													return {
														image: (
															<Media
																id={image.id}
																fluid={true}
																lazy={false}
															/>
														),
													};
												}
											)}
										/>
									);
								}
							})()}
						</div>

						<div className="Template-map" id="map">
							<TintedMap
								styles={[...mapStyle]}
								fitBounds={true}
								lat={entry.map_markers[0].latitude}
								lng={entry.map_markers[0].longitude}
								markers={entry.map_markers.map((m) => {
									let url = m.url ? m.url : entry.map_link;
									let label = m.label
										? m.label
										: `View on Google Maps`;
									return {
										lat: m.latitude,
										lng: m.longitude,
										onClick: () => {
											window.open(url);
										},
										content: (
											<div
												className="Map-pin"
												title={label}
												style={{
													background:
														entry.pin_background,
													color: entry.pin_background,
												}}
											>
												<div className="Map-pin-logo">
													<Media
														id={entry.map_pin}
														fluid={false}
														ratio={5 / 4}
														title={label}
													/>
												</div>
											</div>
										),
									};
								})}
							/>
						</div>

						{(() => {
							if (
								entry.call_to_action_text &&
								entry.call_to_action_url
							) {
								return (
									<div className="Template-section Template-section--desktop">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url}
											>
												{entry.call_to_action_text}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_2 &&
								entry.call_to_action_url_2
							) {
								return (
									<div className="Template-section Template-section--desktop">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_2}
											>
												{entry.call_to_action_text_2}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_3 &&
								entry.call_to_action_url_3
							) {
								return (
									<div className="Template-section Template-section--desktop">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_3}
											>
												{entry.call_to_action_text_3}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						{(() => {
							if (
								entry.call_to_action_text_4 &&
								entry.call_to_action_url_4
							) {
								return (
									<div className="Template-section Template-section--desktop">
										<div className="Template-page">
											<Link
												className="Link Link--callToAction"
												to={entry.call_to_action_url_4}
											>
												{entry.call_to_action_text_4}
											</Link>
										</div>
									</div>
								);
							}
						})()}

						<Entry
							id={[entry.team]}
							render={(team) => {
								return (
									<div className="Template-section">
										<div className="Template-page">
											<Item
												modifier="page"
												head={
													<Content>
														<h3>
															{team.label.decodeHTML()}
														</h3>
														<h1>
															{team.title.decodeHTML()}
														</h1>
													</Content>
												}
												media={
													<Media
														id={team.portrait}
														fluid={false}
														ratio={6 / 4}
													/>
												}
												foot={
													<Content
														modifier="page"
														html={`<h3>${team.restaurants.decodeHTML()}</h3> ${
															team.content
														}`}
													/>
												}
											/>
										</div>
									</div>
								);
							}}
						/>
					</FadeIn>
				</Template>
			);
		}
	}
}

export default withStore(Home);
