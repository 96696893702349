import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Nl2br from '../_core/components/Nl2br';
import Html from '../_core/components/Html';

import Data from '../_core/models/Data';
import Entry from '../_core/models/Entry';
import Asset from '../_core/models/Asset';
import Media from '../_core/components/Media';
import DateFormat from '../_core/components/DateFormat';

import Masonry from 'react-masonry-component';

import Item from '../components/_elements/Item.js';

import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Project
import Project from '../components/';

class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		return (
			<FadeIn key={entry.id}>
		
			<Template modifiers={["default"]}>

				<div className="Template-section">
					<div className="Template-page">
					<Item
						modifier="template"
						head={
							<Content>
								<h1>{entry.title.decodeHTML()}</h1>
								<Html html={entry.content} />
							</Content>
						}
						body={
							false
						}
						media={false}
						foot={<Content html={entry.contact_details} />}
					/>
					</div>
				</div>
				

				<div className="Template-gallery">
					<Media id={entry.image} fluid={true} />
				</div>

			</Template>
			
			</FadeIn>
		
		);

	}
}

export default withStore(Default);
