import React, { Component } from 'react';

import { withStore } from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Entry from '../_core/models/Entry';
import Item from '../components/_elements/Item.js';
import Media from '../_core/components/Media';
import ViewportProgress from '../_core/components/ViewportProgress';
import PageBuilder from './pagebuilder';

// Project
import Project from '../components/';

class Default extends Component {
	slides = 0;
	trackRef = false;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA', false);
	}

	componentWillUnmount() {}

	scrollTrack = ({ progress }) => {
		//console.log('track',progress,this.trackRef,this.slides);

		if (!this.trackRef) return;

		let _this = this;

		let pos = progress * (50 * (this.slides + 1));
		let max = 50 * (this.slides - 1);

		let move = Math.min(pos, max);
		window.TweenLite.set(this.trackRef, { x: `-${move}vw` });
	};

	/*

		RENDER

	*/

	render() {
		let _this = this;

		let { entry } = this.props;

		return (
			<Template>
				<FadeIn key={entry.id}>
					<div className="Template-intro">
						<Content modifier="intro" html={entry.content} />
					</div>
					<div className="Template-section">
						<div className="Template-features">
							<Content modifier="intro">
								<h2>{entry.featured_team_title.decodeHTML()}</h2>
							</Content>
							<div
								className="Template-features-list"
								data-grid-of="4"
								data-tablet-grid-of="3"
								data-mobile-grid-of="1">
								<Entry
									id={entry.featured_team}
									render={(team) => {
										return (
											<div>
												<Item
													modifier="team"
													head={
														<Content
															modifier="page"
															html={`
												<h3>${team.title.decodeHTML()}</h3>
												${team.content}
											`}
														/>
													}
													media={
														<Media
															id={team.portrait}
															fluid={false}
															ratio={4 / 6}
														/>
													}
													foot={false}
												/>
											</div>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="Template-section">
						<div className="Template-features">
							<Content modifier="intro">
								<h2>{entry.senior_management_team_title.decodeHTML()}</h2>
							</Content>
							<div
								className="Template-features-list"
								data-grid-of="4"
								data-tablet-grid-of="3"
								data-mobile-grid-of="1">
								<Entry
									id={entry.senior_management_team}
									render={(team) => {
										return (
											<div>
												<Item
													modifier="team"
													head={
														<Content
															modifier="page"
															html={`
												<h3>${team.title.decodeHTML()}</h3>
												${team.content}
											`}
														/>
													}
													media={
														<Media
															id={team.portrait}
															fluid={false}
															ratio={4 / 6}
														/>
													}
													foot={false}
												/>
											</div>
										);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="Template-sticky-wrapper">
						<ViewportProgress start={0} onProgress={this.scrollTrack}>
							<Entry
								id={entry.team}
								render={(team) => {
									return (
										<div className="Template-section">
											<div className="Template-page">
												<Item
													modifier="teamPage"
													head={
														<Content>
															<h3>{team.label.decodeHTML()}</h3>
															<h1>{team.title.decodeHTML()}</h1>
															<h3>{team.restaurants.decodeHTML()}</h3>
														</Content>
													}
													media={
														<Media
															id={team.portrait}
															fluid={false}
															ratio={6 / 4}
														/>
													}
													foot={
														<Content
															modifier="page"
															html={`${team.content}`}
														/>
													}
												/>
											</div>
										</div>
									);
								}}
							/>
						</ViewportProgress>

						<div className="Template-gallery Template-scroller-wrapper">
							<div className="Template-sticky">
								<Entry
									id={entry.team}
									results={(results) => {
										_this.slides = results.length;

										return (
											<div className="Template-scroller">
												<div
													className="Template-scroller-track"
													ref={(ref) => (_this.trackRef = ref)}>
													{results.map((team) => {
														let width = `${
															(1 / results.length) * 50
														}vw`;
														return (
															<div className="Template-scroller-item">
																<Media
																	id={team.poster_image}
																	fluid={true}
																/>
															</div>
														);
													})}
												</div>
											</div>
										);
									}}
								/>
							</div>
						</div>
					</div>
				</FadeIn>
				<PageBuilder entry={entry} />
			</Template>
		);
	}
}

export default withStore(Default);
