import React, { Component } from 'react';

import Content from '../../_core/components/Content';
import Image from '../../_core/components/Image';
import Link from '../../_core/components/Link';

let defaultProps = {

  media : <Image src={`/ui/img/placeholder/1.jpg`} fluid={false} lazy={true} ratio={6/4} />,
  head : <h1>Title</h1>,
  body : <Content><p>Lorem ipsum dolor sit amet</p></Content>,
  foot : <Link to={`/#`}>Click for more</Link>

}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    let props = this.props;
    
    if(props.placeholder){
      props = {...defaultProps,...props};
    }
   
    return (
      
        <div className={`Item Item--${(props.modifier) ? props.modifier : 'default'}`}>
          {(()=>{
            if(props.media){
              return (
              <div className="Item-media">
                {props.media}
              </div>
              )
            }
          })()}
          <div className="Item-content">

            {(()=>{
              if(props.head){
                return (
                <div className="Item-head">
                  {props.head}
                </div>
                )
              }
            })()}

            {(()=>{
              if(props.innerMedia){
                return (
                <div className="Item-media">
                  {props.innerMedia}
                </div>
                )
              }
            })()}

            {(()=>{
              if(props.body){
                return (
                <div className="Item-body">
                  {props.body}
                </div>
                )
              }
            })()}

            {(()=>{
              if(props.foot){
                return (
                <div className="Item-foot">
                  {props.foot}
                </div>
                )
              }
            })()}
            
          </div>
        </div>
               
    );
  }
}



export default Com;
