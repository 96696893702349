import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import Form from '../_core/components/Form';
import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Entry from '../_core/models/Entry';
import Item from '../components/_elements/Item.js';
import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import ViewportProgress from '../_core/components/ViewportProgress';
import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';
import Data from '../_core/models/Data';

// Project
import Project from '../components/';



class Default extends Component {


	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {entry} = this.props;

		return (
		<FadeIn key={`${entry.id}`}>
			<React.Fragment>
			<Template modifiers={["default","pagebuilder"]}>

				<div className="Template-head">
						<Content html={`<h1>${entry.title.decodeHTML()}</h1>${entry.content}`} />
					</div>

					<div className="Template-filter">
						<Data>
							{
								({CUISINE}) => {

									function onlyUnique(value, index, self) {
									  return self.indexOf(value) === index;
									}

									let cuisines = [];
									entry.items.forEach(i=>{

										if(i.filters && i.filters.length) cuisines = cuisines.concat(i.filters);

									});

									let uniqueCuisines = cuisines.filter(onlyUnique);

									return (
										
										<React.Fragment>
										
					                        <div className="Template-dropdown Template-dropdown--long">
											<Toggle autoclose tag={'div'} className="Dropdown">
					                          <div className="Dropdown-head">
					                            {(CUISINE) ? `${CUISINE.title.decodeHTML()}` : `Filter`}
					                          </div>
					                          <div className="Dropdown-arrow"><Icon glyph type="plus" /></div>
					                          <div className="Dropdown-body">
					                            <ul className="List List--dropdown">
					                            	<li><Link navlink hashLink hashKey={'cuisine'} hashVal={'any'} matchUri={`cuisine/any/`}>All</Link></li>
					                                <Entry id={uniqueCuisines} results={(results)=>{
					                                	
					                                	let filterList = results.propSort('title');
					                                	return filterList.map((c) => (<li><Link navlink hashLink hashKey={'cuisine'} hashVal={c.slug} matchUri={`cuisine/${c.slug}/`}>{c.title.decodeHTML()}</Link></li>))
					                                	
					                                }} />
					                            </ul>
					                          </div>
					                        </Toggle>
					                        </div>

                        
										</React.Fragment>

			                           )
			                    }
			                }
			            </Data>
			          </div>

			     <Data require={['entries']}>
							{
								(data) => {

									let items = entry.items;
									if(data.CUISINE){
										items = items.filter((v)=>(v.hasOwnProperty('filters') && v.filters.indexOf(data.CUISINE.id) != -1));
									}

									return (
									<React.Fragment>
									{items.map( (section,ix) => {
											let uri = section.custom_link;
											return (<div key={`tile_${ix}_${uri}`} className={`Tile Tile--full`}>
											<Item
											modifier="tile"
											head={
												<Content>
													<h3>{section.heading}</h3>
												</Content>
											}
											media={
												<Link to={uri}><Media id={section.image} fluid={true} /></Link>
											}
											body={
												<div>
												<Content html={section.text} />
												<Link className="Link Link--button" to={uri}>{section.call_to_action}</Link>
												</div>
												}



												/>
											</div>)
										})
									}
									</React.Fragment>
									)
								}
							}
					</Data>


			</Template>
			</React.Fragment>
			</FadeIn>
			)

	

	}
}

export default withStore(Default);
