import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import Form from '../_core/components/Form';
import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Entry from '../_core/models/Entry';
import Item from '../components/_elements/Item.js';
import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import ViewportProgress from '../_core/components/ViewportProgress';
import Cookie from '../_core/components/Cookie';
// Project
import Project from '../components/';

/*

Dummy data:
another member
999999999999

*/



const MEMBER_COOKIE = 'JKSMEMBER';

class Default extends Component {

	slides = 0;
	trackRef = false;

	constructor(props) {
		super(props);
		this.state = {modifier : 'idle',loggedIn:0};
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	scrollTrack = ({progress}) => {

		console.log('track',progress,this.trackRef,this.slides);

		if(!this.trackRef) return;
		
		let _this = this;

		let pos = progress * (50 * (this.slides + 1));
		let max = (50 * (this.slides - 1));

		let move = Math.min(pos,max);
		window.TweenLite.set(this.trackRef,{x:`-${move}vw`});

	}

	login = (cookieAPI) => {
		cookieAPI.set(MEMBER_COOKIE,1);
		this.setState({loggedIn:1});
	}

	logout = (cookieAPI) => {
		cookieAPI.set(MEMBER_COOKIE,0);
		this.setState({loggedIn:1});
	}

	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {entry} = this.props;
		_this.slides = entry.offers.length;

		return (
		<FadeIn key={`${entry.id}_${_this.state.loggedIn}`}>
			<React.Fragment>
			<Template modifiers={["default","members"]}>

				<div className="Template-section">
					<div className="Template-page">
					<Item
						modifier="template"
						head={
							<Content>
								<Cookie hasCookie={1} name={MEMBER_COOKIE}>
		                        	{({cookieAPI,cookies})=>(
		                          		<React.Fragment>
		                          			<h1>{entry.welcome_title.decodeHTML()}</h1>

		                          			<Html html={entry.welcome_text} />
		                          			<div className="Item-cta">

		                          				<JumpLink className="Link Link--button Link--dummy" to={window.innerHeight}>View My Offers</JumpLink>

		                          				{(()=>{
		                          					if(entry.welcome_buttons && entry.welcome_buttons.length){
		                          						return (
		                          							entry.welcome_buttons.map((l)=>{

		                          								let to = l.link_to ? [l.link_to] : l.custom_link;

		                          								return (<Link className="Link Link--button" to={to}>{l.text}</Link>)

		                          							})
		                          						)
		                          					}else{
		                          						return null;
		                          					}
		                          				})()}

												<a className="Link Link--button Link--logout" onClick={() => {this.logout(cookieAPI)}}>Logout</a>
											</div>
		                          		</React.Fragment>
		                        	)}
		                     	</Cookie>
		                     	<Cookie hasCookie={0} name={MEMBER_COOKIE}>
		                        	{({cookieAPI,cookies})=>(
		                          		<React.Fragment>
		                          		<h1>{entry.title.decodeHTML()}</h1>
		                          		
		                          		</React.Fragment>
		                        	)}
		                     	</Cookie>
		                     </Content>
						}
						body={
							<React.Fragment>
								<Cookie hasCookie={0} name={MEMBER_COOKIE}>
		                        	{({cookieAPI,cookies})=>(
										<Form modifier={`login Form--${_this.state.modifier}`} onSubmit={(data,e,$,API) => {

						                        e.preventDefault();
						                        _this.setState({modifier:'loading'});
						                   		
						                   		// Lookup member

						                        $.get({
						                          url: `/wp-json/headless/v1/member/`,
						                          dataType: 'json',
						                          data: data,
						                          success: function(response) {
						                          		
						                          		_this.setState({modifier:'idle'});
						                          	

						                              if (response.status == "success") {
						                                 
						                                 // Success!
						                                 _this.login(cookieAPI);

						                              } else {
						                                 
						                                 // Didn't add to list
						                                 API.setErrors([response.msg]);
						                                
						                              }

						                              
						                          },
						                          error: function(XMLHttpRequest, textStatus, errorThrown){
						                          	
						                          	_this.setState({modifier:'idle'});
						                          	//console.log('FORM ERROR',XMLHttpRequest);
						                          	API.setErrors(['There was a problem submitting the form, please try again later']);

						                          }
						                      });
						                       

						                    }}
						                    ajax={false}
						                    method="post"
						                    action={'/'}
						                    submit={'Login'}
						                    fields={[
											{
												title:'Name',
												name : 'name'
											},
											{
												title:'Membership Number',
												name : 'id'
											}
										]} />
								)}
		                     	</Cookie>
		                     	
							</React.Fragment>
						}
						media={false}
						foot={
							<React.Fragment>
								<Cookie hasCookie={0} name={MEMBER_COOKIE}>
		                        	{({cookieAPI,cookies})=>(
										<Content modifier="page" html={entry.content} />
									)}
		    					</Cookie>
		    					<Cookie hasCookie={1} name={MEMBER_COOKIE}>
		                        	{({cookieAPI,cookies})=>(
		                        		<Content html={entry.welcome_call_to_action} />
									)}
		    					</Cookie>
							</React.Fragment>
						}
					/>
					</div>
				</div>
				

				<div className="Template-gallery">
					<Cookie hasCookie={1} name={MEMBER_COOKIE}>
			    		{({cookieAPI,cookies})=>(
							<Media id={entry.welcome_image} key={'welcome_image'} fluid={true} />
						)}
		    		</Cookie>
		    		<Cookie hasCookie={0} name={MEMBER_COOKIE}>
			    		{({cookieAPI,cookies})=>(
							<Media id={entry.image} key={'image'} fluid={true} />
						)}
		    		</Cookie>
				</div>

			</Template>
			<Cookie hasCookie={1} name={MEMBER_COOKIE}>
		    {({cookieAPI,cookies})=>(
			<Template>
				
				<div className="Template-sticky-wrapper">
						<ViewportProgress start={0} onProgress={this.scrollTrack}>
						{entry.offers.map(offer =>{
							return (

								<div className="Template-section">
								<div className="Template-page">
								<Item
									modifier="page"
									head={
										<Content>
											<h3>{offer.pre_header.decodeHTML()}</h3>
											<h1>{offer.title.decodeHTML()}</h1>
										</Content>
									}
									media={<Media id={offer.illustration} fluid={false} ratio={6/4} />}
									foot={<Content modifier="page" html={`${offer.text}`} />}
								/>
								</div>
								</div>

						)})}
						</ViewportProgress>

						<div className="Template-gallery">
							<div className="Template-sticky">
			
								<div className="Template-scroller">
									<div className="Template-scroller-track" ref={(ref)=>_this.trackRef = ref}>
									{entry.offers.map(offer =>{
										return <div className="Template-scroller-item"><Media id={offer.image} fluid={true} /></div>
									})}
									</div>
								</div>
							</div>
						</div>
					</div>

				
			</Template>
			)}
		    </Cookie>
			</React.Fragment>
			</FadeIn>
			)

	

	}
}

export default withStore(Default);
