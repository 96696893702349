import React, { Component } from 'react';

// Router
import {
  Switch, 
  Route,
  Link
} from 'react-router-dom';

// Conf
import conf from './_core/_conf';

// Core
import Site from './_core/components/Site';
import SiteModifier from './_core/components/SiteModifier';
import Content from './_core/components/Content';
import Image from './_core/components/Image';
import Icon from './_core/components/Icon';

// Components
import Swatch from './components/_utils/Swatch';



class Index extends Component {


  constructor(props){

      super(props);
  }

  componentWillMount = () => {

      const _this = this;

  }



  render() {

    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let styles = settings.STYLES;

    return (
      
        <Site>
	        <div className="Site-components">
	        	
            <SiteModifier modifier="components" auto />

	        	<h1>Components</h1>

	      		{/* Palette */}
	      		<h2>Palette</h2>
	      		<div data-grid-of="7" data-mobile-grid-of="4" data-grid-gutters>
	      			{styles.palette.map((swatch)=>(<div><Swatch hex={swatch.hex} label={swatch.label} /></div>))}
	      		</div>

	      		{/* Type */}
	      		<h2>Type</h2>
	      		<div data-grid-of="3" data-mobile-grid-of="1" data-grid-gutters>
	      		{styles.type.map((type)=>(
	      			<div>
	      			<div data-grid-of="1">
	      				<div><h3 className="Site-components-heading3">%u-text--{type.label}</h3></div>
	      				<div><Content modifier={`${type.label}`}>{type.label}</Content></div>
	      			</div>
	      			</div>
	      		))}
	      		</div>
	      		
	      		{/* Content */}

	      		{/* Icons */}
	      		<h2>Icons</h2>
	      		<div data-grid-of="5" data-grid-gutters>
	      		{styles.icons.map((icon)=>(
	      			<div>
	      				<div data-grid-of="1">
		      				<div><h3 className="Site-components-heading3">%u-iconfont--{icon.label}</h3></div>
		      				<div><Icon glyph type={icon.label} /></div>
		      			</div>
	      				
	      			</div>
	      		))}
	      		</div>

	      		{/* Assets */}
	      		<h2>Assets</h2>
	      		<div data-grid-of="5" data-mobile-grid-of="2" data-grid-gutters>
	      		{styles.assets.map((asset)=>(
	      			<div>
	      				<div data-grid-of="1">
		      				<div><h3 className="Site-components-heading3">{asset.src} ({asset.width} x {asset.height})</h3></div>
		      				<div><Image lazy raw fluid={false} ratio={asset.width/asset.height} src={asset.src} /></div>
		      			</div>
	      				
	      			</div>
	      		))}
	      		</div>


				

	        </div>
        </Site>
               
    );
  }
}



export default Index;
