import React, { Component } from 'react';
import Map from '../../_core/components/Map';
import mapTint from "../../_core/helpers/mapTint";

let mapStyle = mapTint('#F0EEEB','#DDD9D5','#C6D7D5','#CDD7D1');
let mapOverrides = [
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "saturation": "-100"
            }
        ]
    }
]
mapStyle = [...mapStyle,...mapOverrides];


let mainMarker = {

  lat: 51.524640,
  lng : -0.087599

}

let placeholder = {

  lat : mainMarker.lat,
  lng : mainMarker.lng,
  styles:[

  ],
  markers : [
    {
      lat : mainMarker.lat + 0.002,
      lng : mainMarker.lng + 0.002,
      content : <div data-title="01"><span>01</span></div>
    },
    {
      lat : mainMarker.lat - 0.002,
      lng : mainMarker.lng - 0.002,
      content : <div data-title="02"><span>02</span></div>
    }
  ]

}

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    let props = {...placeholder , ...this.props}

    let styles = (props.styles.length) ? props.styles : mapStyle;

    return (
      
        <div className="" data-layout="fluid">
          
            <Map className="Map" zoom={17} fitBounds={true} defaultUI={false} onMount={props.onMount} lat={props.lat} lng={props.lng} styles={styles} markers={props.markers.map(
                (marker) => {
                  return {
                  onClick : marker.onClick || null,
                  lat : marker.lat,
                  lng:marker.lng,
                  modifier : marker.modifier || 'default',
                  content : marker.content
                }
              })} />
            
         
        </div>
               
    );
  }
}



export default Com;
