import React, { Component } from 'react';

class Swatch extends Component {


  constructor(props){

      super(props);
  }

  componentWillMount = () => {

      const _this = this;

  }

  render() {    

    return (
      
        <div className="Swatch">
          <div style={{height:`80px`,background:this.props.hex}} />
          <div style={{padding:`5px`,fontSize:`10px`,fontFamily:`monospace`,background:`white`,color:`black`}}>
            ${this.props.label}<br />
            <span className="Swatch-hex" style={{textTransform:`uppercase`}}>{this.props.hex}</span>
          </div>
        </div>
               
    );
  }
}



export default Swatch;
