import React, {Component} from 'react'
import { Data } from '../../_core/models'
import { DataRouter } from '../../_core/controllers'

import Layout from '../../templates/_layout';

let BOOK_COUNT = 0;

class Router extends Component {

  constructor() {
    super()

  }

  

  render () {
      return (
      <DataRouter routes={[
          
          // Reset image on all routes
          {
            route:'(.*)/?',
            data : (matches,data)=> {
              
              let found = false;
              found = data.entries.find(entry=>(entry.uri == window.location.pathname));
              
              const home = (data.hasOwnProperty('HOME')) ? data.HOME : data.entries.find(entry=>(entry.uri == '/'));
              
              return {BOOKING_ID:'jksrestaurants',BOOK_COUNT:BOOK_COUNT,ENTRY:found,READING:false,BOOKING:false,HOME:home,CATEGORY:false,LOCATION:false,PRIVATE:false,OUTSIDE:false,PARTY:false,VENUE:false,ROLE:false};

            }
          },
          {
            route:'#/?book/?(.*)/?',
            data : (matches,data)=> {

              let venueId = (matches.length > 1 && matches[1] != '') ? matches[1] : 'jksrestaurants';
              BOOK_COUNT++;

              return {BOOKING_ID:venueId,BOOKING:true,BOOK_COUNT:BOOK_COUNT}

            }
          },
          {
            route:'category/(.*)/?',
            data : (matches,data)=> {
              
              let catSlug = (matches.length) ? matches[1] : 0;
              let category = false;

              if(catSlug){
                category = data.categories.find((c)=>c.slug == catSlug);

              }
              return {CATEGORY:category}

            }
          },
           {
            route:'location/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : 0;
              let location = false;

              if(slug){
                location = data.entries.find((e)=>((e.slug == slug) && (e.type == 'areas')));
              }

              return {LOCATION:location}

            }
          },
          {
            route:'experience/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : 0;
              let experience = false;

              if(slug){
                experience = data.entries.find((e)=>((e.slug == slug) && (e.type == 'experiences')));
              }

              return {EXPERIENCE:experience}

            }
          },
          {
            route:'cuisine/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : 0;
              let cuisine = false;

              if(slug){
                cuisine = data.entries.find((e)=>((e.slug == slug) && (e.type == 'cuisines')));
              }

              return {CUISINE:cuisine}

            }
          },
          {
            route:'outside/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : false;
              if(slug == 'any') slug = false;

              return {OUTSIDE:slug}

            }
          },
          {
            route:'private/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : false;
              if(slug == 'any') slug = false;

              return {PRIVATE:slug}

            }
          },
          {
            route:'time/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : false;
              if(slug == 'any') slug = false;

              return {TIME:slug}

            }
          },
          {
            route:'party/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : false;
              if(slug == 'any') slug = false;

              return {PARTY:slug}

            }
          },
          {
            route:'venue/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : 0;
              let venue = false;

              if(slug){
                venue = data.entries.find((e)=>((e.slug == slug) && (e.type == 'venues')));
              }

              return {VENUE:venue,VENUE_SLUG:matches[1]}

            }
          },
          {
            route:'role/(.*)/?',
            data : (matches,data)=> {
              
              let slug = (matches.length) ? matches[1] : 0;
              let role = false;

              if(slug){
                role = data.entries.find((e)=>((e.slug == slug) && (e.type == 'roles')));
              }

              return {ROLE:role,ROLE_SLUG:matches[1]}

            }
          },
          {
            route:'/read/(.*)/?',
            data : (matches,data)=> {
              
              let found = false;

              
              if(matches.length > 0){
                let uri = window.location.href.split('/read/')[1];
                found = data.entries.find(entry=>(entry.uri == '/'+uri));
              }

              

              return {READING:((found) ? true : false),CAREER_ID:false,READ_ARTICLE:found}

            }
          },
          {
            route:'/vacancy/(.*)/?',
            data : (matches,data)=> {
              
              let found = false;

              
              if(matches.length > 0){
                let id = window.location.href.split('/vacancy/')[1];
                found = id.split('/')[0];
              }

              

              return {READING:((found) ? true : false),READ_ARTICLE:false,CAREER_ID:found}

            }
          },
          ]}>
          <Data>
              {()=>(

                  <Layout />
              )}
          </Data>
      </DataRouter>
      )
  }
}

export default Router
