import React, { Component } from 'react';

// Router
import {
  Switch, 
  Route,
  Link
} from 'react-router-dom';

// Conf
import conf from './_core/_conf';

// Core
import Loader from './_core/components/Loader';
import Site from './_core/components/Site';

import {Entry,Asset,Data} from './_core/models';

// Components
import Components from './_components';

// Project
import Layout from './templates/_layout';
import DataRouter from './components/Router';

// Cookies
import CookieManager from './_core/components/Cookie/CookieManager';
import CookieMessage from './_core/components/Cookie/CookieMessage';
import CookieTracker from './_core/components/Cookie/CookieTracker';

// Tracking
import GoogleAnalytics from 'react-ga';


class Index extends Component {


  constructor(props){

      super(props);
  }

  componentWillMount = () => {

      const _this = this;

  }



  render() {

    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    

    return (
      <Switch>
        <Route path="/__components" render={()=>{

          return <Components />

        }}/>
        <Route render={()=>{

          return (
            <React.Fragment>
              <CookieTracker init={()=>{

                // Code to initialise when cookies are agreed
                GoogleAnalytics.initialize({

                    trackingId: 'UA-64750078-1',
                    debug: true

                });

                // Atreemo 1
                /*
                const script1 = document.createElement("script");
                script1.src = "https://tracking.atreemo.com/Scripts/TrackingInit.js";
                script1.async = true;
                document.body.appendChild(script1);

                if(window.AtreemoTrackingLbr){
                  window.AtreemoTrackingLbr.init(["JKS, https://"]);
                }

                // Atreemo 2
                const script2 = document.createElement("script");
                script2.src = "https://tracking.atreemo.com/Scripts/Tracking.js";
                script2.async = true;
                document.body.appendChild(script2);
                */
                
                return <div className="CT-init" />;


              }}
              track={(page)=>{
                
                // Code to update on each page view
                GoogleAnalytics.pageview(page);

              }} />
              
              <Loader wait={0} manifest={settings.MANIFEST} progress={()=>{return <div className="Loader"><span></span></div>}} loaded={

                  ()=>(
                  <Site>
                    <Data require={['entries']}>
                      {()=>{

                      return <DataRouter />
                      
                      }}
                    </Data>
                  </Site>
                  )

              }/>
              <CookieMessage />
              </React.Fragment>
          )

        }} />
      </Switch>
      
      
    );
  }
}



export default Index;
