import React, { Component } from 'react';
import Link from '../../_core/components/Link';
import Content from '../../_core/components/Content';
import Icon from '../../_core/components/Icon';
import Burger from '../_elements/Burger';

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    return (
      
        <div className="Header" data-layout>

          <div className="Header-logo">
            <Link to={`/`}><div className="Logo" /></Link>
          </div>

          <div className="Header-text">
            <Link to={`/`}>JKS Restaurants</Link>
          </div>
     
            <div className="Header-burger">
              <Burger />
            </div>
  
          
        </div>
               
    );
  }
}



export default Com;
