import React, { Component } from 'react';

// Conf
import conf from '../../_core/_conf';

// Core

import Cookie from '../../_core/components/Cookie';
import Link from '../../_core/components/Link';
import Form from '../../_core/components/Form';
import Icon from '../../_core/components/Icon';
import Content from '../../_core/components/Content';

import {Data,Asset} from '../../_core/models';
import {withStore} from '../../_core/utils';

let placeholder = {
  title : `Can you please provide your contact details?`,
  submitText : 'Download',
  debug:1,
  downloads : [
    {
      url : '#',
      text: 'Brochure'
    },
    {
      url : '#',
      text: 'Techincal Specification'
    }
  ]

}

/*

 <Cookie>
                        {({cookieAPI,cookies})=>(
                          <Content modifier="notice"><p>We have placed cookies on your device. For more information about our use of cookies, please see our <Link to={'/cookies'}>Cookies</Link> and  <Link to={'/privacy-policy'}>Privacy Notice</Link>. Unless you change your cookie settings, we assume you're ok to continue.</p><p><a href="javascript:;" className="Link Link--button" onClick={()=>cookieAPI.set('agreeCookies',1)}>I Agree</a></p></Content>
                        )}
                      </Cookie>


*/

class DownloadForm extends Component {

  ids = {};
  intro = null;
  outro = null;

  constructor(props){

      super(props);
      this.state = {modifier:'default'};
  }

  progress = ({progress}) => {
    if(!this.outro) return;
    this.outro.progress(progress);
  }
  
  setRefId = (el) => {
    if(!el) return;
    this.ids[el.getAttribute('id')] = el;
  }

  componentDidMount = () => {

    let _this = this;
  
  }

  unlock = (cookieAPI) => {

    
    this.setState({'modifier':'loaded'},()=>{
      cookieAPI.set('downloadForm',1);
    });
    
   }

  render() {


    let _this = this;
    let props = {...placeholder,...this.props};


    return (
 
        <div className={`DownloadForm DownloadForm--${this.state.modifier}`}>

    
            <Cookie hasCookie={(0||props.debug)} name={'downloadForm'}>
              {({cookieAPI,cookies})=>(
                <div>
                    <Content modifier="downloads">
                      <h2>{props.title}</h2>
                    </Content>
                    <Form
                   
                    onSubmit={(data,e,$) => {

                        e.preventDefault();
                        _this.setState({'modifier':'loading'});

                        if(!window.csrfTokenValue){
                          _this.unlock(cookieAPI);
                          return;
                        }

                        // Add the CSRF Token
                        let csrfTokenName = window.csrfTokenName;
                        let csrfTokenValue = window.csrfTokenValue;

                        data[csrfTokenName] = csrfTokenValue;

                   
                        $.post({
                          url: '/',
                          dataType: 'json',
                          data: data,
                          success: function(response) {
                              if (response.success) {
                                  
                                  _this.unlock(cookieAPI);

                              } else {
                                  // response.error will be an object containing any validation errors that occurred, indexed by field name
                                  // e.g. response.error.fromName => ['From Name is required']
                                  alert('An error occurred. Please try again.');
                              }

                              
                          }
                      });
                       

                    }}
                    ajax={false}
                    method="post"
                    action={'/'}
                    submit={props.submitText}
                    fields={[
                  {
                    name:'action',
                    type:'hidden',
                    value:'contact-form/send'
                  },
                  {
                    name:'subject',
                    type:'hidden',
                    value:'Download data capture'
                  },
                  {
                    name:'message',
                    type:'hidden',
                    value:`Download data capture`
                  },
                  {
                    name: 'fromName',
                    type: 'text',
                    title: 'Your name',
                    placeholder: 'Name'
                  },
                  {
                    name: 'fromEmail',
                    type: 'email',
                    title: 'Email address',
                    placeholder: 'Email'
                  }
                  ]}>
                    </Form>


                </div>
              )}
            </Cookie>
            <Cookie hasCookie={(1 && !props.debug)} name={'downloadForm'}>
              {({cookieAPI,cookies})=>(
                
                <div>
                  <Content modifier="downloads">
                    <h2>Downloads</h2>
                  </Content>
                  <ul className="List List--downloads">
                  {props.downloads.map((link) => (<li><a target="_blank" href={link.url}>{link.text}</a></li>))}
                  </ul>
                </div>

                 
              )}
            </Cookie>                     
        </div>
    );
  }
}



export default withStore(DownloadForm);
