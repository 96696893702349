import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import Template from '../_core/components/Template';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		

		return (
			
			<Template>

				<h1>Page Not Found</h1>

			</Template>
		
		);

	}
}

export default withStore(Default);
