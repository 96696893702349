import React, { Component } from 'react';
import Content from '../../_core/components/Content';
import Icon from '../../_core/components/Icon';
import Link from '../../_core/components/Link';
import LogoTicker from './LogoTicker.js';
import Html from '../../_core/components/Html';
import Data from '../../_core/models/Data';
import Project from '../../components';

class Com extends Component {


  constructor(props){

      super(props);
  }

  componentDidMount = () => {

      const _this = this;

  }

  render() {    

    return (
      
        <div className="Footer" data-layout>

            <div className="Footer-contact">
              <Data require={['HOME']}>
              { (data) => {


                  return (

                    <React.Fragment>
                      

                        <Content modifier="small">
                          <Html html={data.HOME.footerNewsletter} />
                        </Content>
                        <Project.Newsletter />
                        <Content modifier="small">
                          <Html html={data.HOME.footerContact} />
                          <p>{data.HOME.globalPhone}</p>
                        </Content>
                        
                        <Content modifier="legal">
                          <p>© {new Date().getFullYear()} JKS Restaurants Ltd. </p>
                          <ul className="List List--legal">
                            {data.structures.footer.map(
                                (v,ix) => (<li key={ix}><a href={`${v.uri}`}>{v.title}</a></li>)
                              )}
                          </ul>
                        </Content>

                        <ul className="List List--social">
                          {data.structures.social.map((l,ix) => (
                            <li key={ix}><Link to={l.uri}><Icon glyph type={l.slug} /></Link></li>
                          ))}
                        </ul>

                        <Content modifier="credit">
                          <p>Design by <a href="https://e-i-b.com">Everything In Between</a></p>
                        </Content>
                      </React.Fragment>
                  )

              }}
              </Data>
            </div>
            <div className="Footer-ticker">
              <LogoTicker colour="white" />
            </div>

         
        </div>
               
    );
  }
}



export default Com;
