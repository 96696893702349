import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import Form from '../_core/components/Form';
import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Entry from '../_core/models/Entry';
import Item from '../components/_elements/Item.js';
import Media from '../_core/components/Media';
import Html from '../_core/components/Html';
import ViewportProgress from '../_core/components/ViewportProgress';


// Project
import Project from '../components/';



class Default extends Component {


	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {entry} = this.props;
		if(!entry.hasOwnProperty('sections')) return null;

		if(!entry.sections || !entry.sections.length) return null;

		function Section({section}){
			switch(section.acf_fc_layout){

				case 'full_width_text':
				return <Project.FullWidth item={section} />
				case 'multiple_sections' :
				return <Project.InteractiveSlides items={section.sections} />
				case 'items':
				return <Project.Items items={section.items} />
				case 'page':
				return <Project.Page item={section} />
				case 'tile' :

				let uri = (section.custom_link) ? section.custom_link : [section.link_to];
				return (<div className={`Tile Tile--${section.size}`}>
				<Item
				modifier="tile"
				head={
					<Content>
						<h3>{section.heading}</h3>
					</Content>
				}
				media={
					<Link to={uri}><Media id={section.image} fluid={true} /></Link>
				}
				body={
					<div>
					<Content html={section.text} />
					<Link className="Link Link--button" to={uri}>{section.call_to_action}</Link>
					</div>
					}



					/>
				</div>)
			}

			return null;
		}

		return (
		<FadeIn key={`${entry.id}`}>
			<React.Fragment>
			<Template modifiers={["default","pagebuilder"]}>

				{entry.sections.map( (s,ix) => {
						return <Section key={'section_'+ix} section={s} />
					})
				}

			</Template>
			</React.Fragment>
			</FadeIn>
			)

	

	}
}

export default withStore(Default);
