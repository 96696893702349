import React, { Component } from 'react';

import Form from '../../_core/components/Form';
import FadeIn from '../../_core/components/FadeIn';
import JumpLink from '../../_core/components/JumpLink';
import Template from '../../_core/components/Template';
import Content from '../../_core/components/Content';
import Link from '../../_core/components/Link';
import Image from '../../_core/components/Image';
import Entry from '../../_core/models/Entry';
import Item from '../_elements/Item.js';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import ViewportProgress from '../../_core/components/ViewportProgress';

// Project
import Project from '../../components/';


class Default extends Component {



	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	
	}

	componentWillUnmount() {

	}




	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {items} = this.props;

		if(!items.length) return null;

		return (

			<div className="Template-overview-list" data-grid-of="3" data-tablet-grid-of="2" data-mobile-grid-of="1">
					{items.map(
						(entry) => {

							return (
								<div>
									
									<Item
									modifier="items"
									head={
										false
									}
									body={

										<Content html={entry.text} />

									}
									media={

										(()=>{
											if(entry.link_to){
												return (<Entry id={[entry.link_to]} render={(e)=>{
													let prefix = '';
													if(['post'].indexOf(e.type) != -1){
														prefix = '#/read';
													}
													return (<Link className="Link" prefix={prefix} to={e.uri}><Media id={entry.image} fluid={false} ratio={6/4} /></Link>)
												}} />)
											}else{
												return <Link className="Link" to={entry.custom_link}><Media id={entry.image} fluid={false} ratio={6/4} /></Link>
											}
										})()
									}
									foot={
									
											(()=>{
												if(!entry.call_to_action) return null;
												if(entry.link_to){
													return (<Entry id={[entry.link_to]} render={(e)=>{
														let prefix = '';
														if(['post'].indexOf(e.type) != -1){
															prefix = '#/read';
														}
														return (<Link className="Link Link--button" prefix={prefix} to={e.uri}>{entry.call_to_action}</Link>)
													}} />)
												}else{
													return <Link className="Link Link--button" to={entry.custom_link}>{entry.call_to_action}</Link>
												}
											})()
									
									} />

								</div>
							)

						}
					)}
					</div>
			)

	

	}
}

export default Default;
