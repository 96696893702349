import React, { Component } from 'react';

import Form from '../../_core/components/Form';
import FadeIn from '../../_core/components/FadeIn';
import JumpLink from '../../_core/components/JumpLink';
import Template from '../../_core/components/Template';
import Content from '../../_core/components/Content';
import Link from '../../_core/components/Link';
import Image from '../../_core/components/Image';
import Entry from '../../_core/models/Entry';
import Item from '../_elements/Item.js';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import ViewportProgress from '../../_core/components/ViewportProgress';

// Project
import Project from '../../components/';


class Default extends Component {



	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
	
	}

	componentWillUnmount() {

	}




	/*

		RENDER

	*/

	render() {

		let _this = this;

		let {item} = this.props;

		return (

			<Template modifiers={["default"]}>

				<div className="Template-sticky-wrapper">
					<div className="Template-page">
					<Item
						modifier="template"
						head={
							<Content html={item.header} />
						}
						body={
							false
						}
						media={false}
						foot={<Content html={item.footer} />}
					/>
					</div>
				</div>
				

				<div className="Template-gallery">
					<Project.Carousel modifier="gallery"
						items={item.gallery.map((image)=>{
							return {
								image : <Media id={image} fluid={true} />
							}
						})}
					/>
				</div>

			</Template>
			)

	

	}
}

export default Default;
