import GoogleAnalytics from "react-ga";
import theme from "./theme.js";
import {
	WP_pagesTransformer,
	WP_mediaTransformer,
	WP_structuresTransformer,
} from "./transformers/wordpress.js";

const IS_PRODUCTION = window.location.hostname == "jksrestaurants.com";
const d = new Date();
const CACHEBUSTER =
	IS_PRODUCTION && 0
		? "?nc=" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
		: "?nc=" + d.getTime();

// //jks.content.webcdn.network/
const HOST = IS_PRODUCTION ? "//jksrestaurants.com/" : "/json/";

const settings = {
	SITENAME: "Sitename",
	BRAND: "Brand name",
	STRAPLINE: "Strapline",
	GA_ID: false, // Analytics ID
	STYLES: theme,
	MANIFEST: [
		{
			name: "frontpage",
			src: HOST + "data.json" + CACHEBUSTER,
			type: "json",
			subset: "frontpage",
		},
		{
			name: "entries",
			src: HOST + "entries.json" + CACHEBUSTER,
			type: "json",
			transformer: WP_pagesTransformer,
		},
		{
			name: "assets",
			src: HOST + "assets.json" + CACHEBUSTER,
			type: "json",
			transformer: WP_mediaTransformer,
		},
		{
			name: "structures",
			src: HOST + "data.json" + CACHEBUSTER,
			type: "json",
			subset: "structures",
			transformer: WP_structuresTransformer,
		},
		{
			name: "instagram",
			src: HOST + "data.json" + CACHEBUSTER,
			subset: "instagram",
			type: "json",
		},
		{
			name: "categories",
			src: HOST + "data.json" + CACHEBUSTER,
			subset: "categories",
			type: "json",
		},
	],
	GOOGLE_MAPS_API_KEY: "AIzaSyCjRCWpgYjZlbscLr-SQU9keAZkrwcWzAo", // JKS Maps ID
};

export default settings;
