import React, { Component } from 'react';

import {withStore} from '../_core/utils/Store';

import FadeIn from '../_core/components/FadeIn';
import JumpLink from '../_core/components/JumpLink';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import Link from '../_core/components/Link';
import Image from '../_core/components/Image';
import Media from '../_core/components/Media';

import IsVisible from '../_core/components/IsVisible';

import Asset from '../_core/models/Asset';

import * as Breakpoint from '../_core/utils/Breakpoints';


// Project
import Project from '../components/';
import Item from '../components/_elements/Item.js';

class Home extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		
		this.props.store.store('CTA',false);
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;

		let items = entry.carousel;

		return (
			
			<Template modifiers={["home"]}>
				<FadeIn key={entry.id}>

					<div className="Template-carousel">
						<Project.Carousel modifier="hero" items={items.map((item,ix)=>{

							let uri = (item.customLink != "") ? item.customLink : item.linkTo;

							return {

								image : (
									<Item
									modifier="hero"
									head={(<IsVisible className="Item-logo"><Media id={item.logo} fluid={false} lazy={false} ratio={8/4} /></IsVisible>)}
									media={(<Link to={uri}>
										<Asset id={[item.image]} render={(image)=>{

											return (
												<React.Fragment>
												<Breakpoint.Mobile>
												<Image src={image.sizes.medium} lazy={false} fluid={true} />
												</Breakpoint.Mobile>
												<Breakpoint.Default>
												<Image src={image.sizes.original} lazy={false} fluid={true} />
												</Breakpoint.Default>
											</React.Fragment>
											)
										}} />
										
										</Link>)}
									foot={false} />
								)

							}

						})} />
					</div>

				</FadeIn>
			</Template>
		
		);

	}
}

export default withStore(Home);
