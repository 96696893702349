const CDN_HOSTNAME = '';

const WP_mediaTransformer = (media,store) => {



  let assets = media.filter(item=>(!!item)).map(item => {

    let i = {...item};
    let media = i.media_details;



    if(!media) return {};

    if(!media.width) media.width = 0;
    if(!media.height) media.height = 0;

    let orientation = (media.width > media.height) ? 'landscape' : 'portrait';

    let a = {
          id: i.id,
          title: i.title.rendered,
          filename: i.source_url.split('/').pop(),
          url: i.source_url,
          width: media.width,
          height: media.height,
          orientation: orientation,
          sizes : {
            local : i.source_url,
            original : i.source_url,
            small:i.source_url,
            medium:i.source_url,
            large:i.source_url
          },
          imageAnimation : [],
          animationSpeed : 500,
          inlineVideo : false,
          videoUrl : ''
      };

      if(media.sizes && Object.keys(media.sizes).length){
        a.sizes = {
              local: media.sizes.full.source_url,
              large: media.sizes.full.source_url,
              medium: (media.sizes.hasOwnProperty('large')) ? media.sizes.large.source_url : media.sizes.full.source_url,
              small: (media.sizes.hasOwnProperty('medium_large')) ? media.sizes.medium_large.source_url : media.sizes.full.source_url,
              original: media.sizes.full.source_url
          }
      }

      if(i.acf && i.acf.imageAnimation && i.acf.imageAnimation.images.length){

        a.imageAnimation = i.acf.imageAnimation.images.map(i=>(i.image));
        if(i.acf.imageAnimation.animationSpeed && i.acf.imageAnimation.animationSpeed != '') a.animationSpeed = parseInt(i.acf.animationSpeed);

      }

      if(i.acf && i.acf.videoUrl && i.acf.videoUrl.length){

        a.videoUrl = i.acf.videoUrl;
        a.inlineVideo = i.acf.inlineVideo;

      }

  

      return a;

  })
  
  return assets;

}

const WP_pagesTransformer = (pages,store) => {

  // If thre is an __ENTRY variable already in the window, merge its data

  if(window.__ENTRY){
    let WINDOW_ENTRY = {...window.__ENTRY};
    let existing = pages.find(p=>(p.id == window.__ENTRY.ID));
    

    WINDOW_ENTRY = {...WINDOW_ENTRY ,  title : {rendered: WINDOW_ENTRY.page_title}, content : {rendered :  WINDOW_ENTRY.post_content} };

    if(existing){
      
      pages[pages.indexOf(existing)] = {...existing ,  ...WINDOW_ENTRY };


    }else{
      // Make a new entry...
      pages.push({...WINDOW_ENTRY ,slug: '__ENTRY', uri : '/__ENTRY/', link : '/__ENTRY/' });
    }

  }

  let entries = pages.map(item => {

    let i = {...item};
    i.url = i.link.replace(/(http:|https:)?\/\/[^\/]+/,'');
    i.uri = i.url;

    if(i.id == store.frontpage.id){

      i.url = '/';
      i.uri = '/';
    }

    i.title = i.title.rendered;
    //i.template = 'default';
    i.content = (i.hasOwnProperty('content')) ? i.content.rendered : '';
    i.postDate = i.date;

    if(i.template){
      i.template = i.template.replace('template-','').replace('.php','');
    }else{
      i.template = i.type;
    }

    if(i.acf){
      i = Object.assign(i, i.acf);
      delete i.acf; 
    }

    if(i.blocks){
      i.blocks = i.blocks.map(block=>({...block,type:block.acf_fc_layout}))
    }

    if(i.ticker){

      i.ticker = i.ticker.map(item=>{

        let copy = {...item};

        if(item.linkTo || item.customLink){
          copy.uri = (item.customLink) ? item.customLink : item.linkTo.replace(CDN_HOSTNAME,'');
        }

        return copy;

      });

    }


    return i;

  })

  return (store.hasOwnProperty('posts')) ? entries.concat(store.posts) : entries;

}

const WP_careersTransformer = (pages,store) => {

  const mapRole = (oldRole) => {

    let map = {
      'central-reservations' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'finance' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'marketing' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'people-team' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'property' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'sales' : {
        post_name : 'head-office',
        post_title : 'Head Office'
      },
      'foh-management' : {
        post_name : 'front-of-house',
        post_title : 'Front of House'
      },
      'foh-line-level' : {
        post_name : 'front-of-house',
        post_title : 'Front of House'
      },
      'boh-management' : {
        post_name : 'kitchen',
        post_title : 'Kitchen'
      },
      'boh-line-level' : {
        post_name : 'kitchen',
        post_title : 'Kitchen'
      }
    }
    // Clone the original related role
    let mappedRole = {...oldRole}

    // Swap the post_name and post_title for the item in the map
    // The key in the map should match the post_name in the original data
    if(map.hasOwnProperty(mappedRole.post_name)){

      mappedRole['post_name'] = map[oldRole.post_name].post_name;
      mappedRole['post_title'] = map[oldRole.post_name].post_title;
    }

    return mappedRole;

  }

  let entries = pages.map(item => {

    let i = {...item};
    i.url = i.link;
    i.uri = i.url;

    i.application_url = i.url;


    i.title = i.title.rendered + ' — ' + i.related_venue.title;
    //i.template = 'default';
    i.content = (i.hasOwnProperty('content')) ? i.content.rendered : '';
    i.postDate = i.date;

    if(i.hasOwnProperty('related_role')){
      i.related_role = mapRole(i.related_role);
    }

    return i;

  })

  return entries;

}

const WP_menuTransformer = (menu) => {

  return menu.map(item => {

    let url = item.type == 'custom' ? item.url : item.url.replace(/(http:|https:)?\/\/[^\/]+/,'');
    let i = {
      id:item.ID,
      slug: item.title.replace(/\s+/g, '-').toLowerCase(),
      title : item.title,
      uri : url.replace(CDN_HOSTNAME,''),
      children : WP_menuTransformer(item.children)
    };

    return i;

  })

}

const WP_structuresTransformer = (structures) => {

  let transformed = {};
  Object.keys(structures).forEach(k=>{

    transformed[k] = WP_menuTransformer(structures[k].filter(item => (item.menu_item_parent == "0")));

  })
  
  if(!transformed.main) transformed.main = [];
  if(!transformed.footer) transformed.footer = [];
  if(!transformed.secondary) transformed.secondary = [];
  if(!transformed.footer) transformed.footer = [];
  if(!transformed.shortcuts) transformed.shortcuts = [];
  if(!transformed.header) transformed.header = [];
  if(!transformed.locations) transformed.locations = [];
                  
  return transformed;
}


export {
    WP_mediaTransformer,
    WP_pagesTransformer,
    WP_menuTransformer,
    WP_structuresTransformer,
    WP_careersTransformer
};


