import React, { Component } from 'react';
import Cookies from './Cookies';
import { withRouter } from "react-router-dom";
import './Cookies.css';

class CookieMessage extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		
	

	}




	/*

		RENDER

	*/

	render() {

		let com = this;
		

		return(
		<Cookies>
			
			{({cookieAPI,cookies,cookieSettings,agreeCookies,declinedCookies})=>{

				
				let preferencesUrl = cookieSettings.PREFERENCES_URL;

				if(com.props.preferencesUrl) preferencesUrl = com.props.preferencesUrl;
				
				return ( 
				<aside className={`Cookie-message ${ (agreeCookies || declinedCookies) ? 'is-inactive' : 'is-active'}`}>
					<h5>Cookies</h5>
					<p>This website places necessary cookies on your computer to improve your experience. We may also use optional analytics cookies to analyse our website traffic.</p>
					<div onClick={()=>cookieAPI.agree()} className="Cookie-message-accept">Accept optional cookies</div> <div onClick={()=>cookieAPI.decline()} className="Cookie-message-decline">Decline optional cookies</div> <div className="Cookie-message-details" onClick={()=>this.props.history.push(preferencesUrl)}>View Cookie Policy</div>
				</aside>
				)


			}}

		</Cookies>)
		

	}
}

export default withRouter(CookieMessage);